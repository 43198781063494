<app-product-breadcrumb [title]="'diritto di recesso'" [breadcrumb]="'diritto di recesso'"></app-product-breadcrumb>
 
<section class="contact-page section-b-space" style="padding-bottom: 100px;">
    <div class="container">
        <div class="row section-b-space">
            <div class="collection-content col">
                <div class="page-main-content">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4">{{rightToWithdraw.DirittoRecesso.title}}</div>
                                    <p [innerHTML]="rightToWithdraw.DirittoRecesso.text"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 pt-4">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{rightToWithdraw.Rimborso.title}}
                                    </div>
                                    <div class="privacy">
                                        <ul>
                                            <li>
                                                <div>
                                                    <p [innerHTML]="rightToWithdraw.Rimborso.text"></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{rightToWithdraw.Restituzione.title}}
                                    </div>
                                    <div class="privacy">
                                        <ul>
                                            <li>
                                                <div>
                                                    <p [innerHTML]="rightToWithdraw.Restituzione.text"> 
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>
<app-tap-to-top></app-tap-to-top>