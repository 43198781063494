import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/dp-products/product.model';
import { ProductService } from 'src/app/dp-services/product.service';

@Component({
  selector: 'app-product-offers',
  templateUrl: './product-offers.component.html',
  styleUrls: ['./product-offers.component.scss']
})
export class ProductOffersComponent implements OnInit {
  public products: Product[] = [];
  public productCollections: any[] = [];
  
  constructor(public productService: ProductService) { 
  }

  ngOnInit(): void {
    let prodottiCercati = JSON.parse(localStorage['products']);
      //this.products = response.filter(item => item.CategoryID == 1 || item.CategoryID == 2);
      this.products = prodottiCercati;
      // Get Product Collection
      this.products.filter((item) => {
        item.Collection?.filter((collection) => {
          const index = this.productCollections.indexOf(collection);
          if (index === -1) this.productCollections.push(collection);
        })
      });
   }

   // Product Tab collection
   getCollectionProducts(collection) {
    return this.products?.filter((item) => {
      if (item.Collection?.find(i => i === collection)) {
        return item
      }
    })
  }

}
