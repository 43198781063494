import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Product } from 'src/app/dp-products/product.model';
import { ProductService } from 'src/app/dp-services/product.service';
import { SliderService } from '../home-service/slider.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public products: Product[] = [];
  public productCollections: any[] = [];

  public sliders = [];
  public slidersWithPhrase = [];

  @ViewChild("newsletter", { static: false }) NewsLetterModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    public productService: ProductService,
    private slidersService: SliderService) {

    // Categories
    this.productService.getProducts.subscribe(response => {
      //this.products = response.filter(item => item.Category == 'Fotocamere digitali' || item.Category == 'Obiettivi');
      this.products = response;
      // Get Product Collection
      for (let item of this.products) {
        if (item.Collection !== undefined && item.Collection !== null) {
          item.Collection.filter((collection) => {
            const index = this.productCollections.indexOf(collection);
            if (index === -1) this.productCollections.push(collection);
          })
        }
      }
    });
  }

  ngOnInit(): void { 
    this.setSlides();
    this.setSlidesWithPhrase();
     
  }

  ngAfterViewInit(): void {
    //this.openModal();
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.NewsLetterModal, { 
        size: 'md',
        ariaLabelledBy: 'NewsLetter-Modal',
        centered: true,
        windowClass: 'theme-modal newsletterm NewsLetterModal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
 
  ngOnDestroy(): void {
    // Remove Color
    document.documentElement.style.removeProperty('--theme-deafult');
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.Collection !== undefined && item.Collection !== null) {
        if (item.Collection.find(i => i === collection)) {
          return item
        }
      }
    })
  }

  private setSlides() {
    this.slidersService.getSlides().subscribe(res => {
      this.sliders = res;
    })
  }

  private setSlidesWithPhrase() {
    this.slidersService.getSlidesWithPhrase().subscribe(res => {
      this.slidersWithPhrase = res;
    })
  }
}
