<div class="main-navbar">
  <div id="mainnav">

    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">
      <li class="back-btn">
        <div class="mobile-back text-left" (click)="mainMenuToggle()">
          <span>{{ 'close' | translate }}</span>
          <i class="fa fa-angle-left pl-2" aria-hidden="true"></i>
        </div>
      </li>
      <ng-container *ngFor="let menuItem of menuItems">
        <li *ngIf="menuItem.type === 'link'">
          <!-- Link -->
          <a [routerLink]="menuItem.path" routerLinkActive="active" class="nav-link" (click)="changeMenu(menuItem.path)">
            {{ menuItem.title | translate }}
            <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
            <span class="sub-arrow" *ngIf="menuItem.children.length !== 0"></span>
          </a>
        </li>
        <li *ngIf="menuItem.type === 'sub'">
          <!-- Sub -->
          <a [routerLink]="menuItem.path" class="nav-link" (click)="toggletNavActive(menuItem)">
            {{ menuItem.title | translate }}
            <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
            <span class="sub-arrow" *ngIf="menuItem.children.length !== 0"></span>
          </a>
          <!-- 2nd Level Menu -->
          <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children">
            <ng-container *ngFor="let childrenItem of menuItem.children">
              <li *ngIf="childrenItem.type === 'link' ">
                <!-- Link -->
                <a [routerLink]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  {{ childrenItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                  <span class="sub-arrow" *ngIf="childrenItem.children.length !== 0"></span>
                </a>
              </li>
              <li *ngIf="childrenItem.type === 'sub'">
                <!-- Sub -->
                <a href="javascript:void(0)" (click)="toggletNavActive(childrenItem)">
                  {{ childrenItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
                  <span class="sub-arrow" *ngIf="childrenItem.children.length !== 0"></span>
                </a>

                <!-- 3rd Level Menu -->
                <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
                  <li *ngFor="let childrenSubItem of childrenItem.children">
                    <!-- Link -->
                    <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                      [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' ">
                      {{ childrenSubItem.title | translate }}
                      <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                        }}</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ng-container>
          </ul>

          <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
            <div class="container">
              <div class="row">
                <div class="col mega-box" *ngFor="let childrenItem of menuItem.children">
                  <div class="link-section">
                    <div class="menu-title">
                      <h5>
                        {{ childrenItem.title | translate }}
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                      </h5>
                    </div>
                    <div class="menu-content opensubmegamenu">
                      <ul *ngIf="childrenItem.children">
                        <li *ngFor="let childrenSubItem of childrenItem.children">
                          <!-- Sub -->
                          <a href="javascript:void(0)" *ngIf="childrenSubItem.type === 'sub'">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                              }}</span>
                          </a>
                          <!-- Link -->
                          <a [routerLink]="childrenSubItem.path" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                              }}</span>
                          </a>
                          <!-- External Link -->
                          <a href="{{ childrenSubItem.path }}" *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                              }}</span>
                          </a>
                          <!-- External Tab Link -->
                          <a href="{{ childrenSubItem.path }}" target="_blank"
                            *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate
                              }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>