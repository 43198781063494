import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  
  constructor(private http: HttpClient) { }

  getCountries(): Observable<any>{
    return this.http.get("./assets/soragna/json/countries.json");
  }

  getStripeToken(): Observable<any>{
    if(environment.production === true) {
      return this.http.get("./assets/stripe.json");
    } else {
      return this.http.get("./assets/stripe-test.json");
    }
  }

  checkIva(paese: string, codice: any): Observable<any>{
    return this.http.get<any>(`${environment.paramUrlBase}/api/check-vat?paese=${paese}&codice=${codice}`); 
  }

  costoSpedizione(country: string, price: number): Observable<any>{ 
    return this.http.get<any>(`${environment.paramUrlBase}/api/shipping/price?country=${country}&totprice=${price}`);
  } 
}
