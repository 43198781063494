import { Component, OnInit } from '@angular/core';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-conditions-sale',
  templateUrl: './conditions-sale.component.html',
  styleUrls: ['./conditions-sale.component.scss']
})
export class ConditionsSaleComponent implements OnInit {
 public faq: any;
  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.contactService.getFAQ().subscribe(response => {
      this.faq = response;
    })
  }

}
