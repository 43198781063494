<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">{{ "shoponline.Categoria" | translate }}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ul class="category-list"> 
                <li *ngFor="let category of categories">
                    <a [routerLink]="['/shop']"
                       [queryParams]="{ category: category.Name }">
                        {{ category.Name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- End Categories List -->