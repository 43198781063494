import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Product } from '../dp-products/product.model';
import { OrderService } from './order.service';

const state = {
  // products: JSON.parse(localStorage['products'] || '[]'),
  //compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  public Currency = { Name: 'Euro', Currency: 'EUR', Price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products;

  constructor(private http: HttpClient,
    private httpOrder: OrderService,
    private toastrService: ToastrService) { }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  private get products(): Observable<Product[]> {
    let lang = localStorage.getItem("css-language");
    if (lang === "" || lang === undefined || lang === null) {
      this.Products = this.http.get<Product[]>(environment.srcProducts + 'products-it.json').pipe(map(data => data));
      this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
      return this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    } else {
      this.Products = this.http.get<Product[]>(environment.srcProducts + 'products-' + lang + '.json').pipe(map(data => data));
      this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
      return this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }
  }

  changeProductsLang(lang: string) {
    return this.http.get<Product[]>(environment.srcProducts + 'products-' + lang + '.json').pipe(map(data => data));
  }

  gethttpProducts() {
    let lang = localStorage.getItem("css-language");
    return this.http.get<Product[]>(`${environment.paramUrlBase}/api/products/all?lang=${lang}`);
  }

  gethttpProductsInCart() {
    let lang = localStorage.getItem("css-language");
    let usercode = localStorage.getItem("css-user-code");
    if (usercode && usercode !== null && usercode !== "") {
      return this.http.get<any>(`${environment.paramUrlBase}/api/customers/cart-products/${usercode}?lang=${lang}`);
    } else {
      this.removeItems();
    }
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  public getMinPrice(): number {
    var min = 0;
    this.getProducts.subscribe(data => {
      if (data.length > 0) {
        min = Math.min(...data.map(a => a.Price));
        return min;
      }
    })

    return min;
  }

  public getMaxPrice(): number {
    var max = 1000;
    this.getProducts.subscribe(data => {
      if (data.length > 0) {
        max = Math.max(...data.map(a => a.Price));
        return max;
      }
    })
    return max;
  }

  // Get Products By Slug
  /*public getProductBySlug(slug: string): Observable<Product> {
    return this.products.pipe(map(items => {
      return items.find((item: any) => {
        return item.Title.replace(' ', '-') === slug;
      });
    }));
  }*/

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  /*// Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.ID === product.ID)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }*/

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  public removeItems(): any {
    state.cart = []
    localStorage.setItem("cartItems", "");
    return true
  }

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    const usercode = localStorage.getItem("css-user-code");
    if (usercode && usercode !== null && usercode !== '') {
      const cartItem = state.cart.find(item => item.ID === product.ID);
      const qty = product.quantity ? product.quantity : 1;
      const items = cartItem ? cartItem : product;
      const stock = this.calculateStockCounts(items, qty);

      var prezzoFinale = product.Price;
      var prodottoScontato = false
      if (product.Discount.ID !== 0 &&
        product.Discount !== undefined &&
        product.Discount !== null) {
        if (product.Discount.OrderMin < product.Quantity || product.Discount.OrderMin === product.Quantity) {
          prodottoScontato = true;
          prezzoFinale = product.Price - product.DiscountPrice;
        }
      }

      var newCart = {
        Quantity: Number(qty),
        CustomerCode: usercode,
        ProductID: product.ID,
        Price: Number(prezzoFinale),
        DiscountActive: prodottoScontato
      }

      this.SaveProductInCart(newCart).subscribe(response => {
        //console.log(response)
      });

      if (!stock) return false

      if (cartItem) {
        cartItem.Quantity += qty
      } else {
        state.cart.push({
          ...product,
          Quantity: qty
        })
      }

      this.OpenCart = true; // If we use cart variation modal
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
      return true;
    } else {
      this.httpOrder.createUserCode().subscribe(res => {
        this.removeItems();
        localStorage.setItem('css-user-code', res);
        const cartItem = state.cart.find(item => item.ID === product.ID);
        const qty = product.quantity ? product.quantity : 1;
        const items = cartItem ? cartItem : product;
        const stock = this.calculateStockCounts(items, qty);
  
        var prezzoFinale = product.Price;
        var prodottoScontato = false
        if (product.Discount.ID !== 0 &&
          product.Discount !== undefined &&
          product.Discount !== null) {
          if (product.Discount.OrderMin < product.Quantity || product.Discount.OrderMin === product.Quantity) {
            prodottoScontato = true;
            prezzoFinale = product.Price - product.DiscountPrice;
          }
        }
  
        var newCart = {
          Quantity: Number(qty),
          CustomerCode: res,
          ProductID: product.ID,
          Price: Number(prezzoFinale),
          DiscountActive: prodottoScontato
        }
  
        this.SaveProductInCart(newCart).subscribe(response => {
          //console.log(response)
        });
  
        if (!stock) return false
  
        if (cartItem) {
          cartItem.Quantity += qty
        } else {
          state.cart.push({
            ...product,
            Quantity: qty
          })
        }
  
        this.OpenCart = true; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      })
      return true;
    }
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, quantity: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.ID === product.ID) {
        const qty = state.cart[index].Quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].Quantity = qty;
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        var cartData = {
          CustomerCode: localStorage.getItem("css-user-code"),
          ProductID: product.ID,
          Quantity: qty
        }
        this.UpdateCart(cartData).subscribe(response => {

        });
        return true
      }
    })
  }

  public updateCartWarranty(product: Product, warranty: number, warrantyAdd: boolean): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.ID === product.ID) {
        if (warrantyAdd) {
          state.cart[index].Warranty4 = warranty * product.Quantity;
          localStorage.setItem("cartItems", JSON.stringify(state.cart));
          return true
        } else {
          state.cart[index].Warranty4 = 0;
          localStorage.setItem("cartItems", JSON.stringify(state.cart));
          return true
        }
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.Quantity + quantity
    const stock = product.Stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock ' + stock + ' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    var cartData = {
      CustomerCode: localStorage.getItem("css-user-code"),
      ProductID: product.ID
    }
    this.DeleteProductFromCart(cartData).subscribe(response => {
      //console.log(response) 
    });
    return true
  }

  // Total amount 
  public cartTotalAmount(spedizione: number, coupon?: number): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      return product.reduce((prev, curr: Product) => {
        let price = curr.Price;
        if (curr.DiscountPrice && curr.Discount.MinOrder <= curr.Quantity) {
          price = curr.Price - curr.DiscountPrice;
        }
        let totalprice = (prev + price * curr.Quantity) * this.Currency.Price;
        if (spedizione === undefined) {
          spedizione = 10.5;
        }
        return totalprice
      }, 0) + (spedizione - coupon);
    }));
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  /*public filterProducts(filter: any): Observable<Product[]> {
    console.log("filter", filter)
    return this.products.pipe(map(product => 
      product.filter((item: Product) => {
        if (!filter.length) return true;
      
        const Tags = filter.every((prev) => { // Match Tags
          if (item.Tags) {
            if (item.Tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }*/
  public filterProducts(subcat: any, categories: any, offer: any): Product[] {
    let prodottiCercati = JSON.parse(localStorage['products']);
    return prodottiCercati.filter((item: Product) => {
      if (subcat.length === 0 && categories.length === 0 && offer.length === 0) {
        return prodottiCercati;
      } else {
        if (subcat.length !== 0) {
          const Tags = subcat.some((prev) => { // Match Tags
            if (item.Tags) {
              if (item.Tags.includes(prev)) {
                if (categories.length !== 0) {
                  const Brands = categories.some((prev) => {
                    if (item.Tags.includes(prev)) {
                      if (!offer.length) return prev;
                      const Offers = offer.some((prev) => {
                        if (item.Tags.includes(prev)) {
                          return prev
                        }
                      })
                      return Offers
                    }
                  })
                  return Brands
                }
                if (offer.length !== 0) {
                  const Offers = offer.some((prev) => {
                    if (item.Tags.includes(prev)) {
                      if (!categories.length) return prev;
                      const Brands = categories.some((prev) => {
                        if (item.Tags.includes(prev)) {
                          return prev
                        }
                      })
                      return Brands
                    }
                  })
                  return Offers
                }
                return true
              }
            }
          })
          return Tags;
        }
        if (categories.length !== 0) {
          const Brands = categories.some((prev) => { // Match Tags
            if (item.Tags) {
              if (item.Tags.includes(prev)) {
                if (subcat.length !== 0) {
                  const Tags = subcat.some((prev) => {
                    if (item.Tags.includes(prev)) {
                      if (!offer.length) return prev;
                      const Offers = offer.some((prev) => {
                        if (item.Tags.includes(prev)) {
                          return prev
                        }
                      })
                      return Offers
                    }
                  })
                  return Tags
                }
                if (offer.length !== 0) {
                  const Offers = offer.some((prev) => {
                    if (item.Tags.includes(prev)) {
                      if (!subcat.length) return prev;
                      const Tags = subcat.some((prev) => {
                        if (item.Tags.includes(prev)) {
                          return prev
                        }
                      })
                      return Tags
                    }
                  })
                  return Offers
                }
                return true;
              }
            }
          })
          return Brands;
        }
        if (offer.length !== 0) {
          const Offers = offer.some((prev) => { // Match Tags
            if (item.Tags) {
              if (item.Tags.includes(prev)) {
                if (categories.length !== 0) {
                  const Brands = categories.some((prev) => {
                    if (item.Tags.includes(prev)) {
                      if (!subcat.length) return prev;
                      const Tags = subcat.some((prev) => {
                        if (item.Tags.includes(prev)) {
                          return prev
                        }
                      })
                      return Tags
                    }
                  })
                  return Brands
                }
                if (subcat.length !== 0) {
                  const Tags = subcat.some((prev) => {
                    if (item.Tags.includes(prev)) {
                      if (!categories.length) return prev;
                      const Brands = categories.some((prev) => {
                        if (item.Tags.includes(prev)) {
                          return prev
                        }
                      })
                      return Brands
                    }
                  })
                  return Tags
                }
                return true
              }
            }
          })
          return Offers
        }
      }
    });
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.ID < b.ID) {
          return -1;
        } else if (a.ID > b.ID) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.Title < b.Title) {
          return -1;
        } else if (a.Title > b.Title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.Title > b.Title) {
          return -1;
        } else if (a.Title < b.Title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.Price < b.Price) {
          return -1;
        } else if (a.Price > b.Price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.Price > b.Price) {
          return -1;
        } else if (a.Price < b.Price) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  getProductByID(id: string) {
    var lingua = localStorage.getItem('css-language');
    return this.http.get<any>(`${environment.paramUrlBase}/api/products/product/${id}?lang=${lingua}`);
  }

  public shipping(spedizione): any {
    localStorage.setItem("shipping", JSON.stringify(spedizione));
    return true;
  }

  SaveProductInCart(postdata: any) {
    let data = JSON.stringify(postdata);
    return this.http.post(`${environment.paramUrlBase}/api/customers/save-in-cart`, data);
  }

  UpdateCart(postdata: any) {
    let data = JSON.stringify(postdata);
    return this.http.post(`${environment.paramUrlBase}/api/customers/update-cart`, data);
  }

  DeleteProductFromCart(postdata: any) {
    let data = JSON.stringify(postdata);
    return this.http.post(`${environment.paramUrlBase}/api/customers/delete-from-cart`, data);
  }

  getProductsOffers() {
    let lang = localStorage.getItem("css-language");
    return this.http.get<Product[]>(`${environment.paramUrlBase}/api/products/offers?lang=${lang}`);
  }
}
