import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CartModalComponent } from 'src/app/dp-modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from 'src/app/dp-modal/quick-view/quick-view.component';
import { ProductService } from 'src/app/dp-services/product.service';
import { Product } from '../product.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string;
  public srcImg = environment.srcImg;

  constructor(private productService: ProductService, private router: Router) { }

  ngOnInit(): void {
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  /*addToCompare(product: any) {
    this.productService.addToCompare(product);
  }*/

  changePage(url: string) {
    console.log(url)
    this.router.navigate(['/shop/', url]).then(() => {
      console.log("changed");
      window.location.reload()
    });
  }
}
