<app-product-breadcrumb [title]="'condizioni di vendita'" [breadcrumb]="'condizioni di vendita'">
</app-product-breadcrumb>
 

<section class="contact-page section-b-space" style="padding-bottom: 100px;">
    <div class="container">
        <div style="padding-top: 40px;"></div>
        <div class="row section-b-space">
            <div class="collection-content col">
                <div class="page-main-content">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4">{{faq.Condizioni.title}}</div>
                                    <p [innerHTML]="faq.Condizioni.text"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 pt-4">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{faq.PagamentiAccettati.title}}</div>
                                    <div class="privacy" [innerHTML]="faq.PagamentiAccettati.text"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{faq.Fattura.title}}
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.Fattura.text">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{faq.Garanzia.title}}
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.Garanzia.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{faq.Spedizioni.title}}
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.Spedizioni.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{faq.DirittoRecesso.title}}
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.DirittoRecesso.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2">{{faq.RestituzioneMerce.title}}
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.RestituzioneMerce.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4" [innerHTML]="faq.TerminiCondizioni.title"></div>
                                    <p [innerHTML]="faq.TerminiCondizioni.text"> </p> <br>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.DaSapere.title"> </div>
                                    <div class="privacy" [innerHTML]="faq.DaSapere.text">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.CONDIZIONI.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.CONDIZIONI.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.Registrazione.title"></div>
                                    <div class="privacy" [innerHTML]="faq.Registrazione.text1"></div>
                                    <div class="privacy" [innerHTML]="faq.Registrazione.text2"></div>
                                    <div class="privacy" [innerHTML]="faq.Registrazione.text3"></div><br>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ContenutiSito.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ContenutiSito.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.AccessoRisorseEsterne.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.AccessoRisorseEsterne.text">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.UsoAmmesso.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.UsoAmmesso.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4" [innerHTML]="faq.TERMINIECONDIZIONI.title"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ProdottiAPagamento.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ProdottiAPagamento.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.DescrizioneProdotto.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.DescrizioneProdotto.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ProceduraAcquisto.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ProceduraAcquisto.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.InvioOrdine.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.InvioOrdine.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.Prezzi.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.Prezzi.text">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.MezziPagamento.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.MezziPagamento.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.AutorizzazionePayPal.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.AutorizzazionePayPal.text"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.RiservaProprieta.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.RiservaProprieta.text"> </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.Consegna.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.Consegna.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.MancataConsegna.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.MancataConsegna.text"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4" [innerHTML]="faq.DirittiUtente.title"></div> <br>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.DirittoRecesso2.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.DirittoRecesso2.text"> </div>
                                    <div class="privacy">
                                        <div class="contact-title" [innerHTML]="faq.DirittoRecesso2.subtitleA">
                                        </div>
                                        <p [innerHTML]="faq.DirittoRecesso2.textA">
                                        </p> <br>
                                    </div>
                                    <div class="privacy">
                                        <div class="contact-title" [innerHTML]="faq.DirittoRecesso2.subtitleB">
                                        </div>
                                        <p [innerHTML]="faq.DirittoRecesso2.textB"></p><br>
                                    </div>
                                    <div class="privacy">
                                        <div class="contact-title" [innerHTML]="faq.DirittoRecesso2.subtitleC">
                                        </div>
                                        <p [innerHTML]="faq.DirittoRecesso2.textC"></p> <br>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.GaranziaProdotto.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.GaranziaProdotto.text"> </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4" [innerHTML]="faq.LimitazioneResponsabilita.title"></div> <br>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.UtentiEuropei.title">
                                    </div> <br>
                                    <div class="privacy">
                                        <div class="contact-title" [innerHTML]="faq.UtentiEuropei.subtitleA">
                                        </div>
                                        <p [innerHTML]="faq.UtentiEuropei.textA">
                                        </p> <br>
                                    </div>
                                    <div class="privacy">
                                        <div class="contact-title" [innerHTML]="faq.UtentiEuropei.subtitleB">
                                        </div>
                                        <p [innerHTML]="faq.UtentiEuropei.textB"> </p> <br>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4" [innerHTML]="faq.DisposizioniComuni.title"></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.NessunaRinuncia.title"> 
                                    </div>
                                    <div class="privacy"  [innerHTML]="faq.NessunaRinuncia.text"> </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.InterruzioneServizio.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.InterruzioneServizio.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.RivenditaServizio.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.RivenditaServizio.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.PrivacyPolicy.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.PrivacyPolicy.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ProprietaIntellettuale.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ProprietaIntellettuale.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ModificheTermini.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ModificheTermini.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.CessioneContratto.title">
                                    </div>
                                    <div class="privacy"[innerHTML]="faq.CessioneContratto.text"> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.Contatti.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.Contatti.text"> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ClausolaSalvaguardia.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ClausolaSalvaguardia.text"></div>
                                    <div class="privacy">
                                        <div class="contact-title"[innerHTML]="faq.ClausolaSalvaguardia.subtitleA"> 
                                        </div>
                                        <p [innerHTML]="faq.ClausolaSalvaguardia.textA"> </p>
                                    </div>
                                    <div class="privacy">
                                        <div class="contact-title"[innerHTML]="faq.ClausolaSalvaguardia.subtitleB"> </div>
                                        <p [innerHTML]="faq.ClausolaSalvaguardia.textB"> </p> <br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.LeggeApplicabile.title"></div>
                                    <div class="privacy" [innerHTML]="faq.LeggeApplicabile.text"></div>
                                    <div class="privacy">
                                        <div class="contact-title"[innerHTML]="faq.LeggeApplicabile.subtitleA"></div>
                                        <p [innerHTML]="faq.LeggeApplicabile.textA"> </p> <br><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ForoCompetente.title"> </div>
                                    <div class="privacy" [innerHTML]="faq.ForoCompetente.text"></div>
                                    <div class="privacy">
                                        <div class="contact-title"  [innerHTML]="faq.ForoCompetente.subtitleA"> </div>
                                        <p  [innerHTML]="faq.ForoCompetente.textA"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h2 pt-4"  [innerHTML]="faq.RisoluzioneControversie.title"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="top-banner-wrapper">
                                <div class="top-banner-content">
                                    <div class="h4  pt-2" [innerHTML]="faq.ComposizioneControversie.title">
                                    </div>
                                    <div class="privacy" [innerHTML]="faq.ComposizioneControversie.text"> </div>
                                    <div class="privacy">
                                        <div class="contact-title" [innerHTML]="faq.ComposizioneControversie.subtitleA"> </div>
                                        <p [innerHTML]="faq.ComposizioneControversie.textA"> 
                                        </p> <br>
                                        <div style="border: solid #dddddd 2px;" class="p-2" [innerHTML]="faq.ComposizioneControversie.table"></div>
                                    </div>
                                    <div class="privacy pt-4">
                                        <div class="small-text">Ultima modifica: 20 settembre 2022</div>
                                        <br> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-tap-to-top></app-tap-to-top>