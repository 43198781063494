<owl-carousel-o [options]="ProductSliderConfig" class="home-slider" [ngClass]="class" *ngIf="sliders">
	<ng-container *ngFor="let slider of sliders">
		<ng-template carouselSlide>
			<section class="section-b-space pb-4 background-slider-prom">
				<div class="collection-wrapper">
					<div class="container">
						<div class="row blog-media promotion-slider">
							<div class="col-md-5 promotion-image">
								<img src="{{srcImg + slider.ImageSrc}}" class="img-fluid" alt="{{slider.ImageSrc}}"
									style="max-height: 120px; width: auto;display: inline;" />
							</div>
							<div class="col-md-7 promotion-text">
								<p class="text mt-2 mb-0"><span class="gold-text"><strong>{{ slider.Title |
											translate }}</strong></span></p>
								<p>{{ slider.Subtitle | translate }}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</ng-template>
	</ng-container>
</owl-carousel-o>