import { Component, Input, OnInit } from '@angular/core';
import { VisitSlider } from '../slider';

@Component({
  selector: 'app-slider-visit',
  templateUrl: './slider-visit.component.html',
  styleUrls: ['./slider-visit.component.scss']
})
export class SliderVisitComponent implements OnInit {

  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;

  constructor() { }

  ngOnInit(): void {
  }

  public VisitSliderConfig: any = VisitSlider;
}
