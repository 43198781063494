<owl-carousel-o [options]="VisitSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
	  <ng-template carouselSlide>
	    <div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+slider.image+')'}">
	      <div class="container">
	        <div class="row">
	          <div class="col">
	            <div class="slider-contain" style="justify-content: center;">
	              <div style="z-index: 1000; margin: auto; right: 0;  left: 0;">
					<app-services class="display: block;"></app-services>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </ng-template>
	</ng-container>
</owl-carousel-o> 