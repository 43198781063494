import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'; 
import { Product } from '../../product.model';

@Component({
  selector: 'app-product-offer',
  templateUrl: './product-offer.component.html',
  styleUrls: ['./product-offer.component.scss']
})
export class ProductOfferComponent implements OnInit {
  @Input() title: string = "";
  @Input() products: Product[] = [];
  @Input() types: any[] = [];

  @Output() offersFilter: EventEmitter<any> = new EventEmitter<any>();
  
  offers: any[] = [];

  public collapse: boolean = true;

  constructor() {}

  ngOnInit(): void {
   this.offers = [
    {
      Name: "Offerta"
    }, 
    {
      Name: "Sconto"
    }
   ]
  }

  get filterbyType() {
    const uniqueTypes = [];
    this.offers.filter((offer) => {
      if(offer.Name){
        const index = uniqueTypes.indexOf(offer.Name);
        if (index === -1) uniqueTypes.push(offer.Name);
      }
    })
    return uniqueTypes;
  }

  appliedFilter(event) {
    let index = this.types.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked){
      this.types.push(event.target.value); // push in array cheked value
    } else {
      this.types.splice(index,1);  // removed in array unchecked value  
    }
    
    let offers = this.types.length ? { offer: this.types.join(",") } : { offer: null };
    this.offersFilter.emit(offers);
  }

  // check if the item are selected
  checked(item){
    if(this.types.indexOf(item) != -1){
      return true;
    }
  }

}
