<div class="collection-collapse-block" [class.open]="collapse" *ngIf="filterbyType.length">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">{{ title | translate }}</h3>
    <div class="collection-collapse-block-content">
      <div class="collection-brand-filter">
        <div class="custom-control custom-checkbox collection-filter-checkbox" 
            *ngFor="let tipo of filterbyType">
          <input 
            type="checkbox" 
            [value]="tipo"
            [checked]="checked(tipo)"
            [id]="tipo"
            (change)="appliedFilter($event)"
            class="custom-control-input" />
          <label class="custom-control-label" for="{{ tipo }}">{{ tipo | translate }}</label>
        </div>
      </div>
    </div>
</div>