import { Component, OnInit } from '@angular/core';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  public policy: any;

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.contactService.getPrivacyPolicy().toPromise().then(response => {
      this.policy = response;
    })
  }

}
