import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailConfirmComponent } from 'src/app/dp-modal/email-confirm/email-confirm.component';
import { ContactMail } from '../contact.model';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  AcceptPrivacy: boolean;

  @ViewChild("emailSent") emailSent: EmailConfirmComponent;

  constructor(private contactService: ContactService,
    private router: Router,
    private formBuilder: FormBuilder) { }

  mailForm!: FormGroup;
  newMessage: ContactMail;
  submitted = false;

  ngOnInit(): void {
    this.AcceptPrivacy = false;
    this.mailForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.maxLength(50)]],
      LastName: ['', [Validators.required, Validators.maxLength(50)]],
      Phone: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(15)]],
      Email: ['', [Validators.required, Validators.email]],
      Text: ['', [Validators.required, Validators.maxLength(400)]],
      EmptyField: ['', [Validators.nullValidator]]
    });
  }

  get form() {
    return this.mailForm.controls;
  }


  sendMail() {
    this.submitted = true;
    if (this.mailForm.valid) {
      const FirstName = this.mailForm.get('FirstName')?.value;
      const LastName = this.mailForm.get('LastName')?.value;
      const Phone = this.mailForm.get('Phone')?.value;
      const Email = this.mailForm.get('Email')?.value;
      const Text = this.mailForm.get('Text')?.value;
      const EmptyField = this.mailForm.get('EmptyField')?.value;

      if (EmptyField === "" || EmptyField === null || EmptyField === undefined) {
        this.newMessage = {
          FirstName: FirstName,
          LastName: LastName,
          Phone: Phone.toString(),
          Email: Email,
          Text: Text,
          EmptyField: "", 
          ProductID: ""
        }

        this.contactService.postMail(this.newMessage).subscribe(response => {
          this.submitted = false;
          this.mailForm.reset();
          this.emailSent.openModal();
        });
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  openNewTab() {
    window.open('/privacy', '_blank');
  }

  disableButton(){
    this.AcceptPrivacy = !this.AcceptPrivacy;
  }
}