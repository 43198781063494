<header class="sticky header-tools" [ngClass]="class" [class.fixed]="stick && sticky"><!-- header-tools-->
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="header-dropdown">
            <ul>
              <li>{{'menu.benvenuti' | translate}}</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i> {{'menu.chiamateci'  | translate}}: +39 0524 599073</li>
            </ul>
          </div>
        </div>
        <div class="col text-right">
          <div class="header-dropdown">
            <ul>
              <li (click)="goFacebook()"><i class="fa fa-facebook-f" aria-hidden="true"></i></li>
              <li (click)="goInstagram()"><i class="fa fa-instagram" aria-hidden="true"></i></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--menu principale-->
  <div class=" py-0 my-0">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main-menu">
            <div class="menu-left">
              <div class="toggle-nav" (click)="mainMenuToggle()" style="padding-right: 25px;">
                <i class="fa fa-bars sidebar-bar"></i>
              </div>
              <div class="brand-logo">
                <a routerLink="/" id="headerlogo">
                  <img src="assets/soragna/logo-small.png" class="img-fluid" alt="Soragna">
                </a>
              </div>
            </div>
            <div class="menu-left pull-left">
              <div>
                <app-menu-top (changeTab)="changeMenuPage()"></app-menu-top>
              </div>
              <div>
                <!--icons-->
                <app-settings></app-settings>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>