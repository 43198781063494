import { Component, OnInit } from '@angular/core'; 
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/dp-services/order.service';

@Component({
  selector: 'app-order-shipping',
  templateUrl: './order-shipping.component.html',
  styleUrls: ['./order-shipping.component.scss']
})
export class OrderShippingComponent implements OnInit { 
  constructor(private route: ActivatedRoute, private httpService: OrderService ) { }

  ngOnInit(): void { 
    var codice = this.route.snapshot.queryParams.code;
    this.httpService.redirectShippingCode(codice)
  }
}