import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    var price = (args.DiscountPrice && ((args.Discount.MinOrder <= args.Quantity) || args.Discount.MinOrder === 1)) ? args.Price - args.DiscountPrice : args.Price;
    return price
  }

}
