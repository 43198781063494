<app-product-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-product-breadcrumb>

<!-- section start -->
<section class="p-0" *ngIf="errore && carricamentoDati">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section">
                    <h1>404</h1>
                    <h2>page not found</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- thank-you section start -->
<section class="section-b-space" *ngIf="!errore">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="success-text">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <h3>{{ "shoponline.PaginaCheckoutSuccess.Grazie" | translate }} {{ orderDetails.order.Customer }} {{
                        "shoponline.PaginaCheckoutSuccess.perAverOrdinato" | translate }}.</h3>
                    <p class="text">{{ "shoponline.PaginaCheckoutSuccess.odineEffettuatoCorrettamente" | translate }}.
                        <br>
                        {{ "shoponline.PaginaCheckoutSuccess.AbbiamoRicevutoPagamentoXOrdine" | translate }} <b
                            class="gold-text">{{ orderDetails.order.ID }}</b>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- order-detail section start -->
<section class="section-b-space pt-0" *ngIf="!errore">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="product-order success-text">
                    <p class="text"> {{ "shoponline.PaginaCheckoutSuccess.PrendiamoInGestioneOrdine" | translate }} <b
                            class="gold-text">{{ orderDetails.order.ID }}</b>
                        {{"shoponline.PaginaCheckoutSuccess.ilPrimaPossibile" | translate }}. <br>
                        {{
                        "shoponline.PaginaCheckoutSuccess.RiceveraiEmailSuiProssimiAggiornamentiInModoDaEssereSempreAggiornato"
                        | translate }}..</p>
                </div>
            </div>
            <!--<div class="col-12">
                <div class="product-order success-text">
                    <p class="text"> {{ "shoponline.PaginaCheckoutSuccess.NelCasoInCuiOrdineNonSiaStatoAncoraPagato" | translate }} <b class="gold-text">€ {{ orderDetails.order.TotalPrice }}</b> <br>
                        {{ "shoponline.PaginaCheckoutSuccess.TrasferireA" | translate }}: <b class="gold-text">Caseificio Sociale Soragna</b> <br>
                        IBAN: <b class="gold-text">AAAAAAAAA0980980980</b></p>
                </div>
            </div>-->

            <div class="col-12 pt-2">
                <div class="product-order success-text">
                    <p class="text"> {{ "shoponline.PaginaCheckoutSuccess.PuoiContattarciVia" | translate }} &nbsp;
                        <b class="gold-text">
                            &#115;&#097;&#108;&#101;&#115;&#064;&#099;&#097;&#115;&#101;&#105;&#102;&#105;&#099;&#105;&#111;&#045;&#115;&#111;&#114;&#097;&#103;&#110;&#097;&#046;&#105;&#116;
                        </b> <br>
                        {{ "shoponline.PaginaCheckoutSuccess.PuoiChiamarci" | translate }}
                        <a href="tel:0524 599073">
                            <span class="gold-text">{{ "info-caseificio.telefono" | translate }}</span>
                        </a> {{ "shoponline.PaginaCheckoutSuccess.perQualsiasiEsigenza" | translate }}.
                    </p>
                </div>
            </div>
            <div class="ornament py-4"></div>
            <div class="col-12">
                <div class="text-center">
                    <h3 class="title-yellow-2">{{ "your order details" | translate }}</h3>
                </div>
            </div>
            <div class="col-lg-6 mt-3">
                <div class="product-order">
                    <!--elencare tutti i prodotti ordinati-->
                    <div class="row product-order-detail">
                        <div class="col-7 order_detail">
                            <div>
                                <h4>{{ "shoponline.PaginaCheckoutSuccess.Prodotti" | translate }}</h4>
                            </div>
                        </div>
                        <div class="col-2 order_detail text-center">
                            <div>
                                <h4>{{ "shoponline.PaginaCheckoutSuccess.Quatita" | translate }}</h4>
                            </div>
                        </div>
                        <div class="col-3 order_detail text-right">
                            <div>
                                <h4>{{ "shoponline.PaginaCheckoutSuccess.Prezzo" | translate }}</h4>
                            </div>
                        </div>

                    </div>
                    <div class="row product-order-detail" *ngFor="let product of orderDetails.order.Products">
                        <div class="col-7 order_detail">
                            <div>
                                <h5>{{product.ProductName}}</h5>
                            </div>
                        </div>
                        <div class="col-2 order_detail text-center">
                            <div>
                                <h5>{{product.Quantity}}</h5>
                            </div>
                        </div>
                        <div class="col-3 order_detail text-right">
                            <div>
                                <h5>{{product.Price}} €</h5>
                            </div>
                        </div>
                    </div>
                    <div class="total-sec">
                        <ul>
                            <li>{{ "subtotal" | translate }} <span>€ {{subtotalOrderPrice}}</span>
                                <!--<span>{{ orderDetails.totalAmount | currency:productService?.Currency.currency:'symbol' }}</span>-->
                            </li>
                            <li *ngIf="couponAmount !== 0">
                                {{ "coupon" | translate }} <span>- € {{couponAmount}}</span>
                            </li>
                            <li>{{ "shipping price" | translate }} <span>€ {{orderDetails.order.DeliveryPrice}}</span>
                            </li>
                            <!--<li>{{ "tax" | translate }} <span>$00.00</span></li>-->
                        </ul>
                    </div>
                    <div class="final-total">
                        <h3>{{ "total" | translate }}<span>€ {{ orderDetails.order.TotalPrice }}</span>
                            <!--<span>{{ orderDetails.totalAmount | currency:productService?.Currency.currency:'symbol' }}</span>-->
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mt-3">
                <div class="row order-success-sec">
                    <div class="col-sm-6">
                        <h4>{{ "shoponline.PaginaCheckoutSuccess.RiepilogoOrdine" | translate }}</h4>
                        <ul class="order-detail">
                            <li>{{ "shoponline.PaginaCheckoutSuccess.ordineID" | translate }}: {{ orderDetails.order.ID
                                }}</li>
                            <li>{{ "shoponline.PaginaCheckoutSuccess.ordineData" | translate }}: {{
                                orderDetails.order.CreatedAt | date:'dd/MM/yyyy' }}</li>
                            <li>{{ "shoponline.PaginaCheckoutSuccess.ordineTotale" | translate }}: € {{
                                orderDetails.order.TotalPrice }}
                                <!-- | currency:productService?.Currency.currency:'symbol' -->
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <h4>{{ "shoponline.PaginaCheckoutSuccess.IndirizzoConsegna" | translate }}</h4>
                        <ul class="order-detail">
                            <li>{{ orderDetails.customer.Address }}</li>
                            <li>{{ orderDetails.customer.Town }} ({{orderDetails.customer.StateProvince}})</li>
                            <li>{{ orderDetails.customer.Country }}, {{ orderDetails.customer.PostalCode }}</li>
                            <!--<li>Contact No. {{ orderDetails.shippingDetails.phone }}</li>-->
                        </ul>
                    </div>

                    <!--<div class="col-sm-12 payment-mode mt-3">
                        <h4>{{ "shoponline.PaginaCheckoutSuccess.MetodoPagamento" | translate }}</h4>
                        <p class="text">{{ "credit card" | translate }}</p>
                    </div>
                    <div class="col-md-12">
                        <div class="delivery-sec">
                            <h3>{{ "expected date of delivery" | translate }}</h3>
                            <h2>{{ orderDeliverydate | date:'dd-MM-yyyy'}}</h2>
                        </div>
                    </div>-->
                </div>
            </div>

        </div>
    </div>
    <div class="row p-2 m-2" style="margin-bottom: 120px;">
        <div class="col-12 text-center pb-4">
            <a [routerLink]="['/']" class="btn btn-solid">{{ "home-link" | translate }}</a>
        </div>
    </div>
</section>
<!-- Section ends -->