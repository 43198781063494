import { HttpClient  } from '@angular/common/http';
import { Injectable  } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) {}

  postLogin(postData: any) {
    let data = JSON.stringify(postData);
    return this.http.post<any>(`${environment.paramUrlBase}/api/login`, data);
  }

  logout(postData: any) {
    let data = JSON.stringify(postData);
    return this.http.post<any>(`${environment.paramUrlBase}/api/logout`, data);
  }

  forgotPassword(postData: any) {
    let data = JSON.stringify(postData);
    return this.http.post<any>(`${environment.paramUrlBase}/api/forgot-password`, data);
  }
}
