import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Slide {
  title: string;
  subTitle: string;
  image: string;
  link?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(private http: HttpClient) { }

  public getSlides() {
    return this.http.get<Slide[]>('assets/soragna/json/home-sliders.json');
  }
  public getSlidesWithPhrase() {
    return this.http.get<Slide[]>('assets/soragna/json/home-sliders-parole.json');
  }

  public getVisitSlides() {
    return this.http.get<Slide[]>('assets/soragna/json/visit-sliders.json');
  }
}