<app-product-breadcrumb [title]="'cookie policy'" [breadcrumb]="'cookie policy'"></app-product-breadcrumb>
 
<!--section start-->
<section class="contact-page section-b-space" style="margin-bottom: 50px;">
    <div class="container">
        <div class="row section-b-space">
            <div class="col-12">
                <script id="CookieDeclaration" src="https://consent.cookiebot.com/e646f986-3ec8-4b30-ae14-5586de382fe9/cd.js" type="text/javascript" async></script>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->
 
<app-tap-to-top></app-tap-to-top>    