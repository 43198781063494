import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/dp-services/order.service';
import { PaymentService } from '../cart-service/payment.service';

@Component({
  selector: 'app-product-checkout-unsuccessful',
  templateUrl: './product-checkout-unsuccessful.component.html',
  styleUrls: ['./product-checkout-unsuccessful.component.scss']
})
export class ProductCheckoutUnsuccessfulComponent implements OnInit {
  orderID: any;
  public orderDetails: any;

  constructor(private orderService: OrderService, private route: ActivatedRoute, private paymentService: PaymentService,) { }

  ngOnInit(): void {
    // this.orderService.checkoutItems.subscribe(response => this.orderDetails = response);
    var orderid = this.route.snapshot.params.order_id;
    var paymentid = this.route.snapshot.queryParams.payment;
    if (orderid !== undefined) {
      this.orderID = orderid;
      this.orderService.getOrderByID(this.orderID).toPromise().then(response => {
        this.orderDetails = response;
      })
    }
    if (paymentid !== undefined) {
      var updateStatus = { 
        Status: "errore"
      }
       this.paymentService.updatePaymentStatusPost(updateStatus, paymentid).toPromise().then(response => {
        
       })
    }
  }

}
