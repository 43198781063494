<ng-template #emailSent let-modal>
    <div class="modal-content" style="background-image: none;">
        <div class="modal-header">
            <h4 class="text-center">{{ "notice" | translate }}</h4>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body contact-page section-b-space" style="background-image: none;">
            <div class="container p-0">
                <div class="col-lg-12">
                    <div class="contact-right">
                        <div class="text-center mb-4">
                            {{ "your message has been sent." | translate }}
                        </div>
                        <div class="text-center mt-4">
                            <button class="btn btn-solid" [routerLink]="['/']" (click)="modal.dismiss('Cross click')">
                                Home
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>