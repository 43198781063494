<app-product-breadcrumb [title]="'menu.ricette'" [breadcrumb]="'menu.ricette'"></app-product-breadcrumb>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="ml-5">
                    <div class="ornament"></div><br>
                    <p class="text">
                        <i [innerHTML]="'ricette.descrizione'  | translate"> </i>
                    </p>
                    <div class="ornament"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta1.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta1.jpg" alt="CROSTINI AL PARMIGIANO" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta1.ingredienti'  | translate">  </p>
                            <p class="text" [innerHTML]="'ricette.ricetta1.besciamella'  | translate"> </p>
                            <p class="text" [innerHTML]="'ricette.ricetta1.preparazione'  | translate"> </p>
                            <p class="text" [innerHTML]="'ricette.ricetta1.trattada'  | translate"> </p>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta2.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta2.jpg"  alt="INVOLTINI GUSTOSI" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta2.ingredienti'  | translate">  </p> 
                            <p class="text" [innerHTML]="'ricette.ricetta2.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta3.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta3.jpg"  alt="CARCIOFI FRITTI AL PARMIGIANO" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta3.ingredienti'  | translate">  </p>
                            <p class="text" [innerHTML]="'ricette.ricetta3.besciamella'  | translate"> </p>
                            <p class="text" [innerHTML]="'ricette.ricetta3.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta4.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta4.jpg"  alt="PENNETTE FUME'" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta4.ingredienti'  | translate">  </p> 
                            <p class="text" [innerHTML]="'ricette.ricetta4.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta5.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta5.jpg"  alt="BIGNE' AL PARMIGIANO" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta5.ingredienti'  | translate">  </p> 
                            <p class="text" [innerHTML]="'ricette.ricetta5.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta6.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta6.jpg"  alt="RISOTTO ALLA MILANESE" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta6.ingredienti'  | translate">  </p> 
                            <p class="text" [innerHTML]="'ricette.ricetta6.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta7.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta7.jpg" alt="STRACCIATELLE" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta7.ingredienti'  | translate">  </p> 
                            <p class="text" [innerHTML]="'ricette.ricetta7.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="gold-title text-center"> {{ 'ricette.ricetta8.titolo' | translate}}</h2>
                    </div>
                    <div class="col-md-12 text-center">
                        <img src="assets/soragna/ricette/ricetta8.jpg" alt="TAGLIATELLE AL TARTUFO" class="img-fluid m-1" style="max-height: 300px;">
                    </div>
                    <div class="col-md-12">
                        <div class="ml-5">
                            <p class="text" [innerHTML]="'ricette.ricetta8.ingredienti'  | translate">  </p> 
                            <p class="text" [innerHTML]="'ricette.ricetta8.preparazione'  | translate"> </p> 
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="ornament"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="pb-4"></div>

<app-tap-to-top></app-tap-to-top>