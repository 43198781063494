import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visit-modal',
  templateUrl: './visit-modal.component.html',
  styleUrls: ['./visit-modal.component.scss']
})
export class VisitModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
