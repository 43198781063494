<app-product-breadcrumb [title]="'Visita'" [breadcrumb]="'Visita'"></app-product-breadcrumb>

<!-- section start -->
<section class="p-0" *ngIf="false">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section">
                    <h1>404</h1>
                    <h2>page not found</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- unsuccess section start -->
<section class="section-b-space" *ngIf="visitDate">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="unsuccess-text" *ngIf="visitDate.Canceled == false">
                    <!--<i class="fa fa-times-circle" aria-hidden="true"></i> -->
                    <h3>{{ "shoponline.PaginaCancelReservation.Gentile" | translate }} {{visitDate.FirstName}}
                        {{visitDate.LastName}},</h3>
                    <h3 class="pt-2" *ngIf="statoVisita === 'rimborsabile'">
                        {{ "shoponline.PaginaCancelReservation.VisitaCancellabileConRimborso" | translate }}
                        {{visitDate.VisitDate | date:'dd-MM-yyyy'}} {{ "shoponline.PaginaCancelReservation.alle" | translate }} {{visitDate.VisitTime}}?
                    </h3>
                    <h3 class="pt-4" *ngIf="statoVisita === 'cancellabile'">
                        {{ "shoponline.PaginaCancelReservation.VisitaCancellabileSenzaRimborso" | translate }}
                    </h3>
                    <h3 class="pt-4" *ngIf="statoVisita === 'passata'">
                        {{ "shoponline.PaginaCancelReservation.VisitaPassata" | translate }}
                    </h3> 
                </div>
                <div class="unsuccess-text" *ngIf="visitDate.Canceled == true">
                    <!--<i class="fa fa-times-circle" aria-hidden="true"></i> -->
                    <h3>{{ "shoponline.PaginaCancelReservation.VisitaGiaCancellata" | translate }}  </h3> 
                </div>
            </div>
            <div class="col-12 text-center py-3" *ngIf="visitDate.Canceled == false">
                <a (click)="cancelVisit()" class="btn btn-danger m-1" style="padding: 13px 29px;">{{ "shoponline.PaginaCancelReservation.Cancella" | translate }}</a>
                <a [routerLink]="['/']" class="btn btn-solid m-1">{{ "shoponline.PaginaCancelReservation.Annulla" | translate }}</a>
            </div>
            <div class="col-12 text-center py-3" *ngIf="visitDate.Canceled == true"> 
                <a [routerLink]="['/']" class="btn btn-solid m-1">{{ "home-link" | translate }}</a>
            </div>
        </div>
    </div>
    <div class="row p-2 m-2" style="margin-bottom: 120px;">
        <div class="col-md-12">
            <div class="unsuccess-text"> 
                <p class="text pt-4">
                    {{ "shoponline.PaginaCancelReservation.Contattaci" | translate }} <b class="gold-text"> &#105;&#110;&#102;&#111;&#64;&#99;&#97;&#115;&#101;&#105;&#102;&#105;&#99;&#105;&#111;&#45;&#115;&#111;&#114;&#97;&#103;&#110;&#97;&#46;&#105;&#116; </b> {{
                    "shoponline.PaginaCancelReservation.Esigenza" | translate }}.
                </p>
            </div>
        </div> 
    </div>
</section>
<!-- Section ends -->

<ng-template #visitaCancellata let-modal>
	<div class="modal-content p-4"> 
	  <div class="modal-body" style="background-image: none;">
	    <div class="container">
            <div class="row">
                <div class="col-12 m-2"  *ngIf="statoVisita === 'rimborsabile'">
                    {{ "shoponline.PaginaCancelReservation.VisitaCancellataConRimborso" | translate }}
                </div>
                <div class="col-12 m-2"  *ngIf="statoVisita !== 'rimborsabile'">
                    {{ "shoponline.PaginaCancelReservation.VisitaCancellata" | translate }}
                </div>
                <div class="col-12 text-center">
                    <a [routerLink]="['/']" (click)="modal.dismiss('Cross click')" class="btn btn-solid m-1">{{ "close" | translate }}</a>
                </div>
            </div>
        </div>
	   </div>
	</div>
</ng-template>