<div class="pagitation-owl">
    <owl-carousel-o [options]="HomeSliderConfig">
        <ng-container *ngFor="let slider of sliders">
            <ng-template carouselSlide>
                <div class="container">
                    <div>
                        <div class="slide-title">
                            <i>"{{ slider.title | translate }}"</i>
                        </div>
                        <div class="slide-text mt-2">
                            {{ slider.subTitle | translate }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>