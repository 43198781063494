<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <div class="page-title">
            <h2>{{ title | translate }}</h2>
          </div>
        </div>
        <div class="col-sm-6">
          <nav aria-label="breadcrumb" class="theme-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="'/'"  *ngIf="title !== 'menu.home'">{{ "menu.home"| translate }}</a></li>
              <li class="breadcrumb-item active" aria-current="page" *ngIf="title !== 'menu.home'" > {{ breadcrumb | translate }}</li>
              <li *ngIf="title === 'menu.home'" style="padding: 0.65rem 0.80rem;"> </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb End -->