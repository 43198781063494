<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="modal-bg addtocart">
                        <button type="button" id="close-cart-modal" class="close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="media">
                            <a [routerLink]="['/shop/', product.ShortUrl]"><!--product.Title.replace(' ', '-')-->
                                <img class="img-fluid pro-img" src="{{srcImg+product.ImageSrc}}" [alt]="'Soragna'">
                            </a>
                            <div class="media-body align-self-center text-center">
                                <a [routerLink]="['/shop/', product.ShortUrl]"><!--product.Title.replace(' ', '-')-->
                                    <h6>
                                       <span>{{ "add" | translate }} </span>
                                        <span> <strong>{{ product.Title }}</strong></span>
                                        <span> {{ "to your cart" | translate }}?</span>
                                    </h6>
                                </a>
                                <div class="buttons">
                                    <a (click)="confirm()" class="view-cart btn btn-solid" [class.disabled]="product.Price === 0" [hidden]="product.Price === 0" >{{ "confirm" | translate }}</a>
                                    <a (click)="modal.dismiss('Cross click')" class="continue btn btn-solid">{{ "close" | translate }}</a>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add to cart modal popup end-->