<app-product-breadcrumb [title]="'offers'" [breadcrumb]="'offers'"></app-product-breadcrumb>
 
<!-- Tab product End -->
<div class="title1 section-t-space">
    <h4></h4>
    <h2 class="title-inner1">{{ "special offers" | translate }}</h2>
</div>
<section class="section-b-space p-t-0">
    <div class="container">
        <div class="row">
            <div class="col theme-tab">
                <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab *ngFor="let collection of productCollections">
                        <ng-template ngbTabTitle>{{ collection | translate }}</ng-template>
                        <ng-template ngbTabContent>
                            <div class="no-slider row">
                                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                                    <div class="product-box">
                                        <app-product-box [product]="product" [currency]="productService?.Currency"
                                            [cartModal]="true">
                                        </app-product-box>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>
</section>
<!-- Tab product End -->

<div  class="text-center">
    <button class="btn btn-solid" [routerLink]="['/shop']">{{ "all products" | translate }}</button>
</div>

<div style="padding-bottom: 30px; margin-bottom: 50px;"></div>

<app-tap-to-top></app-tap-to-top>
