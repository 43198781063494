import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { OrderService } from 'src/app/dp-services/order.service';
import { ProductService } from 'src/app/dp-services/product.service';

@Component({
  selector: 'app-product-checkout-success',
  templateUrl: './product-checkout-success.component.html',
  styleUrls: ['./product-checkout-success.component.scss']
})
export class ProductCheckoutSuccessComponent implements OnInit {

  //public orderDetails : Order = {};
  orderID: any;
  public orderDetails: any;
  public orderDeliverydate = new Date();
  public subtotalOrderPrice: string;
  public couponAmount = 0;
  errore = true;
  carricamentoDati = false;

  constructor(public productService: ProductService,
    private orderService: OrderService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void { 
    var orderid = this.route.snapshot.params.order_id;
    if (orderid !== undefined) {
      this.orderService.updateAfterCheckout(orderid).toPromise().then(res => {
        this.orderID = orderid;
        let carrello = this.productService.gethttpProductsInCart().pipe(map(data => data));
        carrello.subscribe(next => {
          if (next !== "null" && next !== null && next !== undefined) {
            localStorage['cartItems'] = JSON.stringify(next);
          } else { 
            localStorage.setItem("shipping", "");
            this.productService.removeItems();
          }
          this.orderService.getOrderByID(this.orderID).toPromise().then(response => {
            if (response !== undefined) {
              this.orderDetails = response;
              this.carricamentoDati = true;
              if (response.order.CouponAmount === undefined || response.order.CouponAmount === 0 || response.order.CouponAmount === null) {
                var subtotale = this.orderDetails.order.TotalPrice - this.orderDetails.order.DeliveryPrice;
                this.subtotalOrderPrice = subtotale.toFixed(2);
              } else {
                this.couponAmount = Number(response.order.CouponAmount);
                var subtotale = this.orderDetails.order.TotalPrice - this.orderDetails.order.DeliveryPrice + Number(response.order.CouponAmount);
                this.subtotalOrderPrice = subtotale.toFixed(2);
              }
              if (response.order.ID !== undefined && response.order.ID !== 0) {
                this.errore = false;
              }
              this.orderDeliverydate = this.orderDetails.order.DeliveryDate;
            }
          })
        });
       
      })
    }
  }
}
