<app-product-breadcrumb [title]="'Register'" [breadcrumb]="'Register'"></app-product-breadcrumb>
<!-- section start -->
<section class="register-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>{{ "create account" | translate }}</h3>
                <div class="theme-card">
                    <form class="theme-form">
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="email">{{ "first name" | translate }}</label>
                                <input type="text" class="form-control" id="fname" placeholder="{{ 'first name' | translate }}" required="">
                            </div>
                            <div class="col-md-6">
                                <label for="review">{{ "last name" | translate }}</label>
                                <input type="password" class="form-control" id="lname" placeholder="{{ 'last name' | translate }}" required="">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <label for="email">{{ "email" | translate }}</label>
                                <input type="text" class="form-control" id="email" placeholder="{{ 'email' | translate }}" required="">
                            </div>
                            <div class="col-md-6">
                                <label for="review">{{ "password" | translate }}</label>
                                <input type="password" class="form-control" id="review" placeholder="{{ 'enter your password' | translate }}" required="">
                            </div>
                            <button class="btn btn-solid">{{ "create account" | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->