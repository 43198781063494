import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VisitService } from 'src/app/dp-services/visit.service';

@Component({
  selector: 'app-success-visit',
  templateUrl: './success-visit.component.html',
  styleUrls: ['./success-visit.component.scss']
})
export class SuccessVisitComponent implements OnInit {

  visitID: any;
  public visitDetails: any;
  errore = true;
  carricamentoDati = false;

  constructor(
    private visitService: VisitService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    var visitid = this.route.snapshot.params.visit_id;
    //todo chiamare be e visualizzare il riassunto della visita
    if (visitid !== undefined) {
      this.visitService.getReservationByID(visitid).toPromise().then(res => {
        if (res) { 
          this.visitID = visitid;
          this.visitDetails = res;
          this.errore = false;
          this.carricamentoDati = true;
        }
      }, error => {this.errore = true;})
    }
  }

}
