import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ProductService } from 'src/app/dp-services/product.service';
import { Product } from '../../product.model';

@Component({
  selector: 'app-related-product',
  templateUrl: './related-product.component.html',
  styleUrls: ['./related-product.component.scss']
})
export class RelatedProductComponent implements OnInit {
  @Input() type: string;

  public products: Product[] = [];

  constructor(public productService: ProductService) { }

  ngOnInit(): void {
    let prodottiCercati = JSON.parse(localStorage['products']); 
    this.products = prodottiCercati.filter(prodotto => prodotto.Type === this.type);
  }

/*  @HostListener("click") onClick(){
    window.location.reload();
  }*/
}
