import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from './dp-services/order.service';
import { ProductService } from './dp-services/product.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  moon: boolean = true;
  todayDate = new Date();

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(
    private meta: Meta,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,
    private orderService: OrderService,
    private prService: ProductService) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('css-language') === undefined || localStorage.getItem('css-language') === null || localStorage.getItem('css-language') === "") {
        if (navigator.language === "de-CH" || navigator.language === "de-AT" || navigator.language === "de-LU" || navigator.language === "de-LI" || navigator.language === "de") {
          localStorage.setItem('css-language', 'de');
          translate.setDefaultLang('de');
        } else if (navigator.language === "it-IT" || navigator.language === "it-CH" || navigator.language === "it") {
          localStorage.setItem('css-language', 'it');
          translate.setDefaultLang('it');
        } else if (navigator.language === "fr-CH" || navigator.language === "fr-LU" || navigator.language === "fr-BE" || navigator.language === "fr") {
          localStorage.setItem('css-language', 'fr');
          translate.setDefaultLang('fr');
        } else {
          localStorage.setItem('css-language', 'en');
          translate.setDefaultLang('en');
        }
      } else {
        translate.setDefaultLang(localStorage.getItem('css-language'));
      }

      translate.addLangs(['en', 'it', 'fr', 'de']);
    }

    if (localStorage.getItem('css-user-code') === undefined || localStorage.getItem('css-user-code') === null || localStorage.getItem('css-user-code') === "") {
      orderService.createUserCode().subscribe(responseData => {
        localStorage.setItem("cartItems", "");
        localStorage.setItem('css-user-code', responseData);
      })
    }

    prService.getProducts.subscribe(data => {
      if (data !== undefined) {  
        localStorage['products'] = JSON.stringify(data);
        let carrello = prService.gethttpProductsInCart().pipe(map(data => data));
        carrello.subscribe(next => {
          if (next !== "null" && next !== null && next !== undefined) {
            localStorage['cartItems'] = JSON.stringify(next);
          }
        });
      }
    })

    if (localStorage.getItem("layoutMode") === "dark") {
      this.moon = false;
      document.body.classList.toggle('dark')
    }


    this.title.setTitle("Caseificio Sociale Soragna | Home");
    this.meta.addTags([
      {
        name: "description",
        content: "Il Caseificio Sociale Soragna dal 1930 produce Parmigiano Reggiano nel rispetto del disciplinare, con il solo latte prodotto dalle vacche dei soci. Vogliamo solo il meglio per le nostre vacche, produciamo solo il meglio per i nostri clienti"
      },
      { name: "keywords", content: "Caseificio Sociale Soragna, Parma, Formaggio, Parmigiano Reggiano, Spaccio, Qualità" },
      { name: "robots", content: "index, follow" },
      { name: "og:title", content: "Caseificio Sociale Soragna || Il Parmigiano Reggiano" },
      { name: "og:type", content: "sito web" },
      { name: "og:url", content: "https://caseificio-soragna.it" },
      //{ name: "og:image", content: "https://caseificio-soragna.it/assets/soragna/logo-only.png" }, //todo da cambiare  
      { name: "og:site_name", content: "Caseificio Sociale Soragna || Il Parmigiano Reggiano" },
      {
        name: "og:description",
        content: "Caseificio Sociale Soragna || dal 1930 produce Parmigiano Reggiano nel rispetto del disciplinare, con il solo latte prodotto dalle vacche dei soci. Vogliamo solo il meglio per le nostre vacche, produciamo solo il meglio per i nostri clienti."
      }
    ]);
  }

  customizeLayoutDark() {
    this.moon = !this.moon;
    if (this.moon === false) {
      localStorage.setItem('layoutMode', "dark");
    } else {
      localStorage.setItem('layoutMode', "");
    }
    document.body.classList.toggle('dark')
  }
}
