<app-product-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-product-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
    <div class="container my-5">
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <!--<h3>{{ "login" | translate }}</h3>-->
                <div class="theme-card">
                    <p class="text-center">{{ "The account can be created with the first order" | translate }}.</p>
                    <form (ngSubmit)="login()" [formGroup]="loginForm">
                        <div class="form-group mt-3">
                            <label for="Email">{{ "Email" | translate }}</label>
                            <input type="text" class="form-control" placeholder="{{ 'Email' | translate }}"
                                formControlName="Email" [ngClass]="{'is-invalid': submitted && form.Email.errors}" />
                            <div *ngIf="submitted && form.Email.errors" class="invalid-feedback">
                                <span *ngIf="form.Email.errors.required">{{ "Required field" | translate }}</span>
                                <span *ngIf="form.Email.errors.email">{{ "Email invalid" | translate }}</span>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <label for="Psw">{{ "password" | translate }}</label>
                            <div class="input-group">
                                <input [type]="hide ? 'password' : 'text'" class="form-control"
                                    placeholder="{{ 'password' | translate }}" autocomplete="on" formControlName="Psw"
                                    [ngClass]="{'is-invalid': submitted && form.Psw.errors}" />
                                <div class="input-group-prepend" (click)="hide = !hide" >
                                    <div class=" mt-3 mx-2" style="cursor: pointer;">
                                        <span class="fa" [ngClass]="{ 'fa-eye-slash': hide, 'fa-eye': !hide }"></span>
                                    </div>
                                </div>
                                <div *ngIf="submitted && form.Psw.errors" class="invalid-feedback">
                                    <span *ngIf="form.Psw.errors.required">{{ "Required field" | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center m-3">
                            <div *ngIf="submitted && userDataInvalid" style="color: #dc3545;">
                                Email o password errati !
                            </div>
                        </div>
                        <p class="text-right mr-4" style="cursor: pointer;" [routerLink]="['/forget-password']"><i>{{ "forgot password" |
                                translate }}?</i></p>
                        <div class="text-center">
                           <span class="mx-1">
                            <button class="btn btn-solid" type="submit">{{ "login" | translate }}</button>
                           </span>
                           <span class="mx-1">
                            <button class="btn btn-solid" [routerLink]="['/']" style="background: #3b3b3b;">{{ "cancel" | translate }}</button>
                           </span>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3">
                <!--right-login<h3>{{ "new customer" | translate }}</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">{{ "create an account" | translate }}</h6>
                    <p>{{ "Sign up for a free account at our store" | translate }}. 
                        {{ "Registration is quick and easy" | translate }}. 
                        {{ "It allows you to be able to order from our shop" | translate }}. 
                        {{ "To start shopping click register" | translate }}.</p>
                    <button class="btn btn-solid" [routerLink]="['/register']">{{ "register" | translate }}</button>
                </div>-->
            </div>
        </div>
    </div>
</section>
<!--Section ends-->