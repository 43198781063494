import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from 'src/app/dp-services/product.service';
import { WidgetsService } from 'src/app/dp-services/widgets.service';
import { Product } from '../../product.model';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent implements OnInit {
  @Input() title: string = "";
  @Input() products: Product[] = [];
  @Input() types: any[] = [];

  @Output() typesFilter: EventEmitter<any> = new EventEmitter<any>();
  
  //public products: Product[] = [];
  public collapse: boolean = true;
  public categories: any;

  constructor(public productService: ProductService,
    private catService: WidgetsService) { 
    
  }

  ngOnInit(): void {
  let prodottiCercati = JSON.parse(localStorage['products']);
  this.products = prodottiCercati;
 
    this.catService.getCategories().subscribe(response => {
      this.categories = response;
    })
  }

  get filterbyCategory() {
    const category = [...new Set(this.products.map(product => product.Category))]
    return category
  }

  get filterbyType() {
    const uniqueTypes = [];
    this.categories.filter((cat) => {
      if(cat.Name){
        const index = uniqueTypes.indexOf(cat.Name);
        if (index === -1) uniqueTypes.push(cat.Name);
      }
    })
    return uniqueTypes;
  }
}
