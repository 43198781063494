import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }


  stripeCallback(paymentData: Object) {
    let data = JSON.stringify(paymentData)
    return this.http.post(`${environment.paramUrlBase}/api/stripe/stripe_checkout`, data)
  }

  addPayment(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/payments/new-payment`, data);
  }
  
  updatePaymentStatus(order_id: number){
    return this.http.get<string>(`${environment.paramUrlBase}/api/payments/payment/${order_id}/update`);
  }

  updatePaymentStatusPost(postData: any, id: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/payments/payment/${id}/update`, data);
  }
}
