<app-product-breadcrumb [title]="'menu.contatti'" [breadcrumb]="'menu.contatti'"></app-product-breadcrumb>

<!--section start-->
<section class="contact-page section-b-space" style="margin-bottom: 50px; padding-top: 40px;">
    <div class="container">
        <div class="row section-b-space">
            <div class="col-lg-6">
                <div class="contact-right">
                    <ul>
                        <li>
                            <div class="contact-icon">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <!--<h6>{{ "contatti.indirizzo" | translate }}</h6>
                                width="600" height="450" -->
                            </div>
                            <div class="media-body">
                                <p>{{ "info-caseificio.indirizzo" | translate }}</p>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d214808.4699046072!2d10.06597011565152!3d44.976787276189945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47805bcc70550bfd%3A0xa7250b95174c11bb!2sCaseificio%20Sociale%20di%20Soragna!5e0!3m2!1sit!2sit!4v1675378531775!5m2!1sit!2sit"
                                    width="400" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </li>
                        <li>
                            <a href="tel:0524 599073">
                                <div class="contact-icon">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                    <!--<h6>{{ "contatti.telefono" | translate }}</h6>-->
                                </div>
                            </a>
                            <div class="media-body">
                                <a href="tel:0524 599073">
                                    <p>{{ "info-caseificio.telefono" | translate }}</p>
                                </a>
                                <p></p>
                            </div>
                        </li>
                    </ul> 
                </div>
            </div>
            <div class="col-lg-6">
                <form (ngSubmit)="sendMail()" [formGroup]="mailForm">
                    <div class="form-row">
                        <div class="col-md-6 mt-3">
                            <label for="name">{{ "contatti.form.Nome" | translate }}</label>
                            <input type="text" class="form-control" formControlName="FirstName"
                                placeholder="{{ 'contatti.form.Nome' | translate }}"
                                [ngClass]="{'is-invalid': submitted && form.FirstName.errors}" />
                            <div *ngIf="submitted && form.FirstName.errors" class="invalid-feedback">
                                <span *ngIf="form.FirstName.errors.required">{{ "contatti.form.Obbligatorio" | translate
                                    }}</span>
                                <span *ngIf="form.FirstName.errors.maxlength">{{ "contatti.form.Max" | translate }} 50
                                    {{ "contatti.form.Caratteri" | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="last-name">{{ 'contatti.form.Cognome' | translate }}</label>
                            <input type="text" class="form-control" formControlName="LastName"
                                placeholder="{{ 'contatti.form.Cognome' | translate }}"
                                [ngClass]="{'is-invalid': submitted && form.LastName.errors}" />
                            <div *ngIf="submitted && form.LastName.errors" class="invalid-feedback">
                                <span *ngIf="form.LastName.errors.required">{{ "contatti.form.Obbligatorio" | translate
                                    }}</span>
                                <span *ngIf="form.LastName.errors.maxlength">{{ "contatti.form.Max" | translate }} 50 {{
                                    "contatti.form.Caratteri" | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="phone">{{ "contatti.form.Telefono" | translate }}</label>
                            <input type="number" class="form-control" formControlName="Phone"
                                placeholder="{{ 'contatti.form.Telefono' | translate }}"
                                [ngClass]="{'is-invalid': submitted && form.Phone.errors}" />
                            <div *ngIf="submitted && form.Phone.errors" class="invalid-feedback">
                                <span *ngIf="form.Phone.errors.required">{{ "contatti.form.Obbligatorio" | translate
                                    }}</span>
                                <span *ngIf="form.Phone.errors.maxlength">{{ "contatti.form.Max" | translate }} 15 {{
                                    "contatti.form.Caratteri" | translate }}</span>
                                <span *ngIf="form.Phone.errors.pattern">{{ "contatti.form.Numeri" | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label for="email">{{ "contatti.form.Email" | translate }}</label>
                            <input type="text" class="form-control" formControlName="Email"
                                placeholder="{{ 'contatti.form.Email' | translate }}"
                                [ngClass]="{'is-invalid': submitted && form.Email.errors}" />
                            <div *ngIf="submitted && form.Email.errors" class="invalid-feedback">
                                <span *ngIf="form.Email.errors.required">{{ "contatti.form.Obbligatorio" | translate
                                    }}</span>
                                <span *ngIf="form.Email.errors.email">{{ "contatti.form.EmailNonValida" | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3" style="display: none;">
                            <label for="EmptyField">{{ "contatti.form.Paese" | translate }}</label>
                            <input type="text" class="form-control" formControlName="EmptyField"
                                placeholder="{{ 'contatti.form.Paese' | translate }}" />
                        </div>
                        <div class="col-md-12 mt-3">
                            <label for="Text">{{ "contatti.form.Messaggio" | translate }}</label>
                            <textarea class="form-control"
                                placeholder="{{ 'contatti.form.ScriviIlTuoMessaggio' | translate }}"
                                formControlName="Text" rows="6"
                                [ngClass]="{'is-invalid': submitted && form.Text.errors}"></textarea>
                            <div *ngIf="submitted && form.Text.errors" class="invalid-feedback">
                                <span *ngIf="form.Text.errors.required">{{ "contatti.form.Obbligatorio" | translate
                                    }}</span>
                                <span *ngIf="form.Text.errors.maxlength">{{ "contatti.form.Max" | translate }} 400 {{
                                    "contatti.form.Caratteri" | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3 d-flex justify-content-center">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="AcceptPrivacy"
                                    (click)="disableButton()" />
                                <label class="control-label form-check-label">
                                    <div (click)="openNewTab()">
                                        <a class="accept-privacy">
                                            {{ "contatti.form.AcceptPrivacy" | translate }}
                                        </a>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3 text-center">
                            <button class="btn btn-solid" type="submit" [disabled]="!AcceptPrivacy">{{
                                "contatti.form.Invia" | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->


<app-email-confirm #emailSent></app-email-confirm>
<app-tap-to-top></app-tap-to-top>