<div *ngIf="!loader">
  <div class="product-wrap">
    <div class="product-wrap img-wrapper">
      <div class="lable-block">
         <span class="lable4" *ngIf="product.New">{{ "new" | translate }}</span> 
        <span class="lable3" *ngIf="product.Sale">{{ "shoponline.sconto" | translate }}</span>
      </div>
      <div class="front">
        <a (click)="QuickView.openModal()">
          <!--[routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"-->
          <div class="product-image"
            *ngIf="product.ImageSrc !== undefined && product.ImageSrc !== '' && product.ImageSrc !== null; else defaultImage">
            <img src="{{srcImg+product.ImageSrc}}" class="img-fluid lazy-loading" alt="Caseifico Soragna" />
          </div>
          <ng-template #defaultImage>
            <div class="product-image">
              <img src="assets/soragna/placeholder.jpg" class="img-fluid lazy-loading" alt="Caseifico Soragna" />
            </div>
          </ng-template>
        </a>
      </div> 
      <div class="cart-info cart-wrap">
        <a title="{{ 'shoponline.DettagliProdotto.AggiungiAlCarrello' | translate }}" (click)="QuickView.openModal()" *ngIf="cartModal && product.Price !== 0">
          <i class="ti-shopping-cart"></i>
        </a>
        <a title="{{ 'shoponline.DettagliProdotto.AggiungiAlCarrello' | translate }}" (click)="addToCart(product)" *ngIf="!cartModal && product.Price !== 0">
          <i class="ti-shopping-cart"></i>
        </a> 
      </div>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <!--<bar-rating [rate]="5" [readOnly]="true"></bar-rating>--> 
      <p class="text pt-1 m-0" [routerLink]="['/shop/', product.ShortUrl]">
        <span class="gold-text">
          {{ product?.Title }}
        </span> 
      </p> 
      <a *ngIf="product?.Category">
        <h6> {{product?.Category}}</h6>
      </a>
      <!-- <p>{{ product?.Description }}</p> -->
      <h4 *ngIf="product?.Price !== 0; else richiestaprezzo">
        {{ product?.Price * currency?.Price | discount:product | currency:currency?.Currency:'symbol' }}
        <del *ngIf="product?.DiscountPrice && product?.Discount.MinOrder === 1"><span class="money"> {{ product?.Price * currency?.Price |
            currency:currency?.Currency:'symbol' }}</span></del>
      </h4>
      <ng-template #richiestaprezzo>
        <a class="p-1" style="color: #6d7e87;" title="{{ 'price on request' | translate }}"
          [routerLink]="['/shop/', product.ShortUrl]">
          <i class="ti-pencil-alt"></i> &nbsp;{{ "price on request" | translate }}
        </a>
      </ng-template>
      <ul>
        <!--*ngIf="Color(product?.variants).length"-->
        <li *ngIf="product.Subcategory">
          {{product?.Subcategory}}
        </li>
      </ul>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>