import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core'; 
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const state = {
  checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
     @Inject(DOCUMENT)
     private document: Document,
     private http: HttpClient) { }

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable(observer => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    var item = {
        shippingDetails: details,
        product: product,
        orderId: orderId,
        totalAmount: amount
    };
    state.checkoutItems = item;
    localStorage.setItem("checkoutItems", JSON.stringify(item));
    localStorage.setItem("cartItems", "");
    //localStorage.removeItem("cartItems");
    //this.router.navigate(['/checkout/success', orderId]);
  }

  createUserCode(){
    return this.http.get<string>(`${environment.paramUrlBase}/api/customers/new-customer-code`);
  }

  updateUserCode(nuovo: string, vecchio: string){
    return this.http.get<string>(`${environment.paramUrlBase}/api/customers/update-customer-code?nuovo=${nuovo}&vecchio=${vecchio}`);
  } 

  saveUserData(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/customers/new-customer`, data);
  }
  
  updateUserData(postData: any, id: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/customers/customer/${id}/update`, data);
  }

  saveOrder(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/orders/new-order`, data);
  }

  orderProductList(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/orders/order-product-list`, data);
  }

  isExistUserCode(id: string){
    return this.http.get<any>(`${environment.paramUrlBase}/api/customers/check-code/${id}`);
  }

  getOrderByID(id: number){
    return this.http.get<{customer: any, order: any, payment: any}>(`${environment.paramUrlBase}/api/orders/order/${id}`);
  }

  getTotalOrderByCustomerCode(code: string){
    return this.http.get<any>(`${environment.paramUrlBase}/api/orders/get-order-total/${code}`);
  }

  redirectShippingCode(codice: string){ 
    const form = this.document.createElement("form");
    form.method = "POST";
    form.target = "_top";
    form.action = `https://vas.brt.it/vas/sped_det_show.hsm`;
    const input = this.document.createElement("input");
    input.type = "hidden";
    input.name = "chiSono";
    input.value = codice; 
    form.append(input); 
    this.document.body.appendChild(form);
    form.submit();
  }

  checkCoupon(email, codice, somma) {
    return this.http.get<any>(`${environment.paramUrlBase}/api/orders/check-coupon/${codice}?somma=${somma}&email=${email}`);
  }

  updateAfterCheckout(id:any) {
    return this.http.get<any>(`${environment.paramUrlBase}/api/stripe/update_cart/${id}`);
  }
} 