import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SliderService } from '../dp-home/home-service/slider.service';
import { EmailConfirmComponent } from '../dp-modal/email-confirm/email-confirm.component';
import { VisitService } from '../dp-services/visit.service';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss']
})
export class VisitComponent implements OnInit {

  @ViewChild("emailSent") emailSent: EmailConfirmComponent;
  totaleVisita = 0;
  visitForm!: FormGroup;
  submitted = false;

  timeOptions: { id: string, ora: string }[] = [];
  orarioVisita: any;
  tomorrow: any;

  caseificiAperti: boolean = false;

  public sliders = [];

  constructor(
    private formBuilder: FormBuilder,
    private slidersService: SliderService,
    private httpVisit: VisitService) { }

  ngOnInit(): void {
    var date = new Date();
    this.caseificiAperti = date < new Date('2024-10-06');
    // add a day
    this.tomorrow = date.setDate(date.getDate() + 1);
     
    this.timeOptions = [
      {
        id: "",
        ora: "Prima selezionare il N° persone, la data e la lingua"
      }
    ]
    this.visitForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.maxLength(50)]],
      LastName: ['', [Validators.required, Validators.maxLength(50)]],
      Phone: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(15)]],
      Email: ['', [Validators.required, Validators.email]],
      Nperson: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.maxLength(2)]],
      Nchild: ['', [Validators.pattern('[0-9]+'), Validators.maxLength(2)]],
      Language: ['italiano', [Validators.required]],
      VisitDate: ['', [Validators.required]],
      VisitTime: ["", [Validators.required]],
      Tasting: [false, [Validators.required]],
      Tasting2: [false, [Validators.required]],
      Paese: ['', [Validators.nullValidator]],
      AcceptPrivacy: [false, [Validators.requiredTrue]]
    });

    this.setSlides();
  }

  get form() {
    return this.visitForm.controls;
  }


  reserveNow() {
    this.submitted = true;
    if (this.visitForm.valid && this.visitForm.get('VisitTime')?.value && this.visitForm.get('VisitTime')?.value !== "") {
      const FirstName = this.visitForm.get('FirstName')?.value;
      const LastName = this.visitForm.get('LastName')?.value;
      const Phone = this.visitForm.get('Phone')?.value;
      const Email = this.visitForm.get('Email')?.value;
      const Nperson = this.visitForm.get('Nperson')?.value;
      const Nchild = this.visitForm.get('Nchild')?.value;
      const Language = this.visitForm.get('Language')?.value;
      const VisitDate = this.visitForm.get('VisitDate')?.value;
      const Tasting = this.visitForm.get('Tasting')?.value;
      const Tasting2 = this.visitForm.get('Tasting2')?.value; 
      const Paese = this.visitForm.get('Paese')?.value;
      const VisitTime = this.visitForm.get('VisitTime')?.value;

      var totaleDaPagare = this.calcolaTotaleVisita(Number(Nperson), Number(Nchild), VisitDate, Tasting, Language, Tasting2)

      if (Paese === '' || Paese === null || Paese === undefined) {
        var newVisitData = {
          FirstName: FirstName,
          LastName: LastName,
          Phone: Number(Phone),
          Email: Email,
          Nperson: Number(Nperson),
          Nchild: Number(Nchild),
          Language: Language,
          VisitDate: VisitDate,
          VisitTime: VisitTime,
          Tasting: Tasting,
          Tasting2: Tasting2,
          Total: totaleDaPagare
        }

        this.httpVisit.saveVisitData(newVisitData).subscribe(response => {
          this.submitted = false;
          this.visitForm.reset();
          var idVisita = response;
          if (idVisita !== undefined) {
            this.makePayment(newVisitData, idVisita);
          }
        })
      }
    }
  }

  makePayment(visitData: any, idVisita: any) {
    //configurazione stripe
    console.log("inizio configurazione stripe");
    console.log("pagamento di: ", visitData.Total, " euro");
    let payload = {
      "amount": visitData.Total * 100,
      "currency": "EUR",
      "email": visitData.Email,
      "visit_id": idVisita,
      "name": visitData.FirstName,
      "surname": visitData.LastName,
      "phone": visitData.Phone,
      "n_person": visitData.Nperson,
      "n_child": visitData.Nchild,
      "visit_date": visitData.VisitDate,
      "visit_time": visitData.VisitTime,
      "language": visitData.Language,
      "tasting": visitData.Tasting,
      "tasting2": visitData.Tasting2,
    }
    this.httpVisit.payVisit(payload).subscribe((link: string) => {
      if (link) {
        window.location.href = link;
        // window.open(link, "_blank");
      }
    }, err => {
      window.location.href = err.error.tex;
      //window.open(err.error.text, "_blank");
    })
  }

  calcolaTotale() {
    const Nperson = this.visitForm.get('Nperson')?.value;
    const Nchild = this.visitForm.get('Nchild')?.value;
    var Tasting = this.visitForm.get('Tasting')?.value;
    var Tasting2 = this.visitForm.get('Tasting2')?.value;
    const VisitLang = this.visitForm.get('Language')?.value;
    const VisitTime = this.visitForm.get('VisitTime')?.value;

    this.totaleVisita = Number(Nperson) * 10 + Number(Nchild) * 7;
    if (Tasting && Tasting === true) {
      if (VisitLang == 'italiano') {
        this.totaleVisita = Number(Nperson) * 18 + Number(Nchild) * 7;
      } else {
        this.totaleVisita = Number(Nperson) * 20 + Number(Nchild) * 7;
      }
    }
    if (VisitTime == '') {
      this.totaleVisita = 0;
    } else {
      const VisitDate = this.visitForm.get('VisitDate')?.value;
      if ((VisitDate == '2024-10-05' || VisitDate == '2024-10-06') && VisitLang == 'italiano') {
        this.totaleVisita = Number(Nperson) * 7;
        if (Tasting === true) {
          this.totaleVisita = Number(Nperson) * 10;
        }
        if (Tasting2 === true) {
          this.totaleVisita = Number(Nperson) * 15;
        }
      }
    }
    //this.changeVisitDate()
  }

  calcolaTotaleVisita(nrPerson: number, nrChilds: number, data: string, degustazione?: boolean, lingua?: string, degustazione2?: boolean) {
    var calcolaTot = 0;

    calcolaTot = nrPerson * 10 + nrChilds * 7;
    if (degustazione && degustazione === true) {
      if (lingua == 'italiano') {
        calcolaTot = nrPerson * 18 + nrChilds * 7;
      } else {
        calcolaTot = nrPerson * 20 + nrChilds * 7;
      }
    }

    if ((data == '2024-10-05' || data == '2024-10-06') && lingua == 'italiano') {
      calcolaTot = nrPerson * 7;
      if (degustazione == true) {
        calcolaTot = nrPerson * 10;
      }
      if (degustazione2 == true) {
        calcolaTot = nrPerson * 15;
      }
    }
    return calcolaTot;
  }

  openNewTab() {
    window.open('/privacy', '_blank');
  }

  private setSlides() {
    this.slidersService.getVisitSlides().subscribe(res => {
      this.sliders = res;
    })
  }

  changeVisitDate() {
    const Nperson = this.visitForm.get('Nperson')?.value;
    const Nchild = this.visitForm.get('Nchild')?.value;
    const VisitDate = this.visitForm.get('VisitDate')?.value;
    const VisitLang = this.visitForm.get('Language')?.value;
    var tot = Number(Nperson) + Number(Nchild);
    if (tot && tot !== 0) {
      this.httpVisit.setVisitTimes(VisitDate, VisitLang, tot).toPromise().then(response => {
        if (response !== undefined && response !== null) {
          this.timeOptions = response;
          this.visitForm.get('VisitTime').setValue(response[0].id);
        } else {
          this.visitForm.get('VisitTime').setValue('');
          this.timeOptions = [
            {
              id: "",
              ora: "Non ci sono orari disponibili, si consiglia di scegliere un altro giorno o lingua."
            }
          ]
        }
        this.calcolaTotale();
      })
    } else {
      this.timeOptions = [
        {
          id: "",
          ora: "Prima selezionare il N° persone, la data e la lingua"
        }
      ]
    }
  }

  changeVisitTime(evento: any) {
    this.visitForm.get('VisitTime').setValue(evento.target.value);
  }
}
