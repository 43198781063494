<app-product-breadcrumb [title]="product?.Title"
    [breadcrumb]="'shoponline.DettagliProdotto.Prodotto'"></app-product-breadcrumb>

<!-- section start -->
<section class="section-b-space" *ngIf="product">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <button type="button" class="close m-2" (click)="openZoomImage()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </button>
                    <div class="d-frex justify-content-center" style="border: 2px solid #e3e3e3;">
                        <img [defaultImage]="'/assets/soragna/placeholder.jpg'"
                        [lazyLoad]="srcImg + product.ImageSrc" src="{{srcImg + product.ImageSrc}}"
                        class="img-fluid"  style="display: block; margin-left: auto; margin-right: auto;" alt="Soragna"> 
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product?.Title }}</h2>
                        <div class="product-description">{{product?.Category}}</div>
                        <div *ngIf="product.Price !== 0">
                            <h4 *ngIf="product?.DiscountPrice && product?.DiscountPrice !== ''">
                                <del *ngIf="product?.DiscountPrice && product?.Discount.MinOrder === 1">{{
                                    product?.Price * productService.Currency?.Price | currency:
                                    productService.Currency?.Currency:'symbol' }}</del>
                                <!--<span>{{ product?.Discount }}% off</span>-->
                            </h4>
                            <h3 class="m-0">
                                {{ (product?.Price | discount:product) * productService.Currency?.Price | currency:
                                productService.Currency?.Currency:'symbol' }}
                            </h3>
                            <div class="mb-2" style="font-size: 12px;"
                                *ngIf="product?.DiscountPrice && product?.Discount.MinOrder > 1">
                                {{product.Discount.Title}} ({{product.Discount.Subtitle}})
                            </div>
                            <div class="product-buttons">
                                <a class="btn btn-solid"
                                    [class.disabled]="counter > product.Stock  && product.Price === 0"
                                    (click)="CartModal.openModal(product)" *ngIf="cartModal && product.Price !== 0">
                                    {{ "shoponline.DettagliProdotto.AggiungiAlCarrello" | translate }}</a>
                                <a class="btn btn-solid" [class.disabled]="counter > product.Stock"
                                    (click)="buyNow(product)">{{ "shoponline.DettagliProdotto.CompraSubito" | translate
                                    }}</a>
                            </div>
                        </div>
                        <div class="product-description ">
                            <div class="col-xl-12 col-md-12 col-sm-12 pt-0">
                                <p [innerHTML]="product.DescriptionLong"></p>
                            </div>
                        </div>


                        <!--<div class="product-description border-product">
                            <div class="container important-detail">
                                <div class="row">
                                    <div class="col-xl-4 col-md-4 col-sm-12">
                                        <h5 class="product-title size-text">
                                            {{ "shoponline.DettagliProdotto.Pagamenti" | translate }}
                                        </h5>
                                    </div>
                                    <div class="col-xl-8 col-md-8 col-sm-12 d-flex flex-row-reverse p-0 m-0">
                                        <div class="payment-card-bottom p-0 m-0">
                                            <ul>
                                                <li>
                                                    <a><img src="assets/images/icon/bank_transfer.png" alt=""
                                                            style="max-height: 50px;"></a>
                                                </li>
                                                <li>
                                                    <a><img src="assets/images/icon/visa.png" alt=""></a>
                                                </li>
                                                <li>
                                                    <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-md-12 col-sm-12 pt-0">
                                        <div class="size-box">
                                            <div><i class="fa fa-minus"></i> {{ "shoponline.DettagliProdotto.CarteCredito" | translate }}</div>

                                            <div><i class="fa fa-minus"></i> {{ "shoponline.DettagliProdotto.Bonifico" | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      <div class="border-product">
                        <h6 class="product-title">share it</h6>
                        <div class="product-icon">
                            <form class="d-inline-block">
                                <button class="wishlist-btn" (click)="addToWishlist(product)">
                                    <i class="fa fa-heart"></i>
                                    <span class="title-font">Add To WishList</span>
                                </button>
                            </form>
                        </div>
                    </div>
                     <div class="border-product">
                       <app-countdown [date]="'Apr 21 2021'"></app-countdown>
                    </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding-top: 30px;"></div>
    <app-related-product *ngIf="product?.Type !== undefined" [type]="product?.Type"></app-related-product>
</section>
<app-size-modal #sizeChart [product]="product"></app-size-modal>
<!-- Section ends -->
<app-email-confirm #emailSent></app-email-confirm>

<app-cart-modal #cartModal [product]="product" [currency]="currency"></app-cart-modal>

<app-tap-to-top></app-tap-to-top>