import { Component, Input, OnInit } from '@angular/core';
import { ProductSlider } from '../../product-slider';
import { DiscountService } from 'src/app/dp-services/discount.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promotion-slider',
  templateUrl: './promotion-slider.component.html',
  styleUrls: ['./promotion-slider.component.scss']
})
export class PromotionSliderComponent implements OnInit {

  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string; 

  public srcImg = environment.srcImg;
  
  constructor(private httpDiscount: DiscountService) { }

  ngOnInit(): void { 
    this.httpDiscount.getDiscounts().toPromise().then(response => { 
      if(response) {
        this.sliders = response;
      }
    }) ;
  }

  public ProductSliderConfig: any = ProductSlider;

}
