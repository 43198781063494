import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/dp-services/order.service';
import { ProductService } from 'src/app/dp-services/product.service';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss']
})
export class BankTransferComponent implements OnInit {
  //public orderDetails : Order = {};
  orderID: any;
  public orderDetails: any;
  public orderDeliverydate = new Date();
  public subtotalOrderPrice: string;
  errore = true;
  carricamentoDati = false;

  constructor(public productService: ProductService,
    private orderService: OrderService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {	
   // this.orderService.checkoutItems.subscribe(response => this.orderDetails = response);
   var orderid = this.route.snapshot.params.order_id;
   if(orderid !== undefined){
     this.orderID = orderid;
     this.orderService.getOrderByID(this.orderID).toPromise().then(response => {
      this.orderDetails = response;
      var subtotale = this.orderDetails.order.TotalPrice - this.orderDetails.order.DeliveryPrice;
      this.carricamentoDati = true;
      this.subtotalOrderPrice = subtotale.toFixed(2);
      if(response.order.ID !== undefined && response.order.ID !== 0){
       this.errore = false;
      }
      //this.orderDeliverydate = this.orderDetails.order.DeliveryDate;
    })
   } 

  }
}
