import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  resetPassword!: FormGroup;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder, private loginService: LoginService) { }

  ngOnInit(): void {
    this.resetPassword =  this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]]
    });
  }

  get form() {
    return this.resetPassword.controls;
  }

  sendEmail(){
    this.submitted = true;

    if (this.resetPassword.valid) {
      const Email = this.resetPassword.get('Email')?.value;

      var setNewPassword = {
        Email: Email
      }

      this.loginService.forgotPassword(setNewPassword).subscribe(response => {
       
      }, error => {
       
      })
    }
  }
}
