import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { WidgetsService } from 'src/app/dp-services/widgets.service';
import { Product } from '../../product.model';

@Component({
  selector: 'app-product-brands',
  templateUrl: './product-brands.component.html',
  styleUrls: ['./product-brands.component.scss']
})
export class ProductBrandsComponent implements OnInit, OnDestroy {
  @Input() title: string = "";
  @Input() products: Product[] = [];
  @Input() brands: any[] = [];

  @Output() brandsFilter: EventEmitter<any> = new EventEmitter<any>();

  subscription: Subscription;
  subbrands: any[] = [];
  
  public collapse: boolean = true;

  constructor(private widgetsService: WidgetsService) {}

  ngOnInit(): void {
    this.subscription = this.widgetsService.getBrands().subscribe(response=>{
      this.subbrands = response;
    })
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get filterbyBrand() {
    const uniqueBrands = [];
    this.subbrands.filter((brand)=>{
      if(brand.Name){
        const index = uniqueBrands.indexOf(brand.Name);
        if (index === -1) uniqueBrands.push(brand.Name);
      }
    })
    /*this.products.filter((product) => {
      if (product.Brand) {
        const index = uniqueBrands.indexOf(product.Brand)
        if (index === -1) uniqueBrands.push(product.Brand)
      }
    });*/
    return uniqueBrands;
  }

  appliedFilter(event) {
    let index = this.brands.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked)   
      this.brands.push(event.target.value); // push in array cheked value
    else 
      this.brands.splice(index,1);  // removed in array unchecked value  
    
    let brands = this.brands.length ? { brand: this.brands.join(",") } : { brand: null };
    this.brandsFilter.emit(brands);
  }

  // check if the item are selected
  checked(item){
    if(this.brands.indexOf(item) != -1){
      return true;
    }
  }
}
