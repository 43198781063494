import { Component, OnInit } from '@angular/core';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-right-to-withdraw',
  templateUrl: './right-to-withdraw.component.html',
  styleUrls: ['./right-to-withdraw.component.scss']
})
export class RightToWithdrawComponent implements OnInit {
  public rightToWithdraw: any;

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
    this.contactService.getRightToWithdraw().subscribe(response => {
      this.rightToWithdraw = response;
    })
  }

}
