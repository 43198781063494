<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-setting">
      <div><img [src]="languageflag" alt="language" class="img-icon" style="height: 20px; max-width: 30px;">
        <i class="ti-world"></i>
      </div>

      <div class="show-div setting">
        <h6>{{ 'languages' | translate }}</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a (click)="changeLanguage(language.code)">
              <img src="{{language.path}}" alt="language" class="img-icon" style="height: 20px; max-width: 30px;">
              {{ language.name }}</a>
          </li>
        </ul>
        <!--<h6>{{ 'currency' | translate }}</h6>
          <ul class="list-inline">
            <li *ngFor="let currency of currencies">
              <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.Name | translate }}</a>
            </li>
          </ul>-->
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div (click)="showCart = !showCart">
        <a [routerLink]="['/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="cart">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>{{ "shoponline.PaginaCarrello.CarrelloVuoto" | translate }}.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length && showCart'>
        <li>
          <div class="buttons"> 
            <span class="float-right">
              <a  class="checkout" (click)="showCart = false"><i style="color: #3b3b3b;" class="fa fa-times"></i></a>
            </span>
          </div> 
        </li>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/shop/', product.ShortUrl]"><!--product.Title.replace(' ', '-')-->
              <img class="mr-3" src="{{srcImg + product.ImageSrc}}" [alt]="'Soragna'">
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/', product.ShortUrl]"><!--product.Title.replace(' ', '-')-->
                <h4>{{ product?.Title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.Quantity }} x
                  {{ product?.Price * productService?.Currency.Price | discount:product |
                  currency:productService?.Currency.Currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div>
            <h5>{{ 'subtotal' | translate }} : <span>{{ getTotal | async |
                currency:productService?.Currency.Currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <span class="float-left">
              <a [routerLink]="['/cart']" class="view-cart">{{ 'view' | translate }}</a>
            </span>
            <span class="float-right">
              <a [routerLink]="['/checkout']" class="checkout">{{ 'checkout' | translate }}</a>
            </span>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>