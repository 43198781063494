import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-breadcrumb',
  templateUrl: './product-breadcrumb.component.html',
  styleUrls: ['./product-breadcrumb.component.scss']
})
export class ProductBreadcrumbComponent implements OnInit {
  @Input() title : string;
  @Input() breadcrumb : string;

  constructor() {
  }

  ngOnInit() : void {  }
}
