import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WidgetsService } from 'src/app/dp-services/widgets.service';
import { Product } from '../../product.model';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss']
})
export class ProductTypeComponent implements OnInit {
  @Input() title: string = "";
  @Input() products: Product[] = [];
  @Input() types: any[] = [];

  @Output() typesFilter: EventEmitter<any> = new EventEmitter<any>();

  categories: any[] = [];

  public collapse: boolean = true;

  constructor(private widgetsService: WidgetsService) { }

  ngOnInit(): void {
    this.widgetsService.getCategories().toPromise().then(response => {
      this.categories = response;
    });
  }

  get filterbyType() {
    const uniqueTypes = [];
    this.categories.filter((cat) => {
      if (cat.Name) {
        const index = uniqueTypes.indexOf(cat.Name);
        if (index === -1) uniqueTypes.push(cat.Name);
      }
    })
    return uniqueTypes;
  }

  appliedFilter(event) {
    let index = this.types.indexOf(event.target.value);  // checked and unchecked value
    if (event.target.checked) {
      this.types.push(event.target.value); // push in array cheked value
    } else {
      this.types.splice(index, 1);  // removed in array unchecked value  
    }

    let types = this.types.length ? { category: this.types.join(",") } : { category: null };
    this.typesFilter.emit(types);
  }

  // check if the item are selected
  checked(item) {
    if (this.types.indexOf(item) != -1) {
      return true;
    }
  }
}
