<section class="p-0 home-section">
    <app-slider [sliders]="sliders" [textClass]="'text-center'" [buttonText]="'home.buttonshop' | translate">
    </app-slider>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="ml-5">
                    <div class="ornament"></div><br>
                    <p class="text">
                        <i>
                            {{ 'home.chisiamo.Fondato-nel-1930-il-Caseificio-La-Granda' | translate}}<br>
                            {{ 'home.chisiamo.era-sinonimo-del-patrimonio-contadino-della-bassa-parmense:' |
                            translate}}<br>
                            {{ 'home.chisiamo.il-latte-il-lavoro-e-il-Parmigiano-Reggiano' | translate}}.
                        </i>
                    </p>
                    <div class="ornament"></div>
                </div>
            </div>
            <div class="col-md-12">
                <h2 class="gold-title text-center"> {{'home.storia.titolo' | translate}}</h2>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-8">
                        <div>
                            <p class="text" [innerHTML]="'home.storia.paragrafo1'  | translate">
                            </p>
                            <p class="text" [innerHTML]="'home.storia.paragrafo2'  | translate">
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 m-0">
                        <div class="text-center">
                            <img src="/assets/soragna/logo-gray.png" alt="Soragna" style="max-width: 80%;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="ornament"></div><br>
            <div class="col-md-12">
                <h2 class="gold-text text-center text-line"><span>{{'home.visite.Caseificio-Sociale-Soragna' |
                        translate}}</span></h2>
                <h2 class="title-brown m-4">{{'home.visite.VENITE-A-VISITARCI' | translate}}</h2>
                <h2 class="gold-text text-center text-line"><span>{{'home.visite.Caseificio-Sociale-Soragna' |
                        translate}}</span></h2>
                <br>
                <p class="text text-center m-1">
                    {{'home.visite.Vi-aspettiamo-per-visite-guidate-su-prenotazione' | translate}}
                    <br>
                    {{'home.visite.Chiamateci-per-un-appuntamento' | translate}}<span class="gold-text">+39 0524
                        599073</span>
                    <br>
                    {{'home.visite.Oppure-prenotate-online' | translate}}
                </p>
                <p class="text-center">
                    <a [routerLink]="['/visits']" class="btn btn-solid">
                        {{'home.visite.Prenota' | translate}}
                    </a>
                </p>
            </div>
        </div>
    </div>
</section>

<section class="section-orario">
    <div class="container">
        <div class="row mx-2">
            <div class="col-md-12">
                <div class="section-title-orario">
                    <h5>{{'home.orari.Orari-apertura' | translate}}</h5>
                </div>
            </div>
            <div class="col-md-12 pt-3 text-center border-orari">
                <div class="text-orario">
                    {{'home.orari.lunedi-sabato' | translate}}
                </div>
                <div class="text-orario">
                    08:30 - 12:30<br>
                    14:30 - 18.30<br>
                </div>
                <div class="text-orario">
                    {{'home.orari.domenica' | translate}}
                </div>
                <div class="text-orario">
                    09:00 - 12:00
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="gold-title text-center"> {{'home.redeiformaggi.titolo' | translate}}</h2>
            </div>
            <div class="col-md-12">
                <div>
                    <p class="text" [innerHTML]="'home.redeiformaggi.paragrafo1'  | translate"> </p>
                    <div class="ornament"></div>
                </div>
            </div>
            <div class="col-md-12">
                <h2 class="gold-title text-center"> {{'home.comesifa.titolo' | translate}}</h2>
            </div>
            <div class="col-md-12">
                <div>
                    <p class="text" [innerHTML]="'home.comesifa.paragrafo1'  | translate"></p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="ornament"></div>
<br>
<!-- Parallax banner start-->
<section class="p-0">
    <div class="full-banner parallax-banner1 parallax text-center p-left p-2"
        [ngStyle]="{'background-image': 'url(assets/soragna/slide3.jpg)'}">
        <div class="title-yellow">
            <span><img src="/assets/soragna/logo_only.png" alt="Soragna" style="max-height: 150px;"></span>
            {{'home.parolediformaggio' | translate}}
        </div>
        <app-slider2 [sliders]="slidersWithPhrase"></app-slider2>
    </div>
</section>
<!-- Parallax banner End -->
<br>
<div class="ornament"></div>
<br>

<app-tap-to-top></app-tap-to-top>

<ng-template #blackfriday let-modal>
    <div class="modal-content" style="background-image: none; left: 0;">
        <div class="modal-body contact-page section-b-space p-0 pb-3" style="background-image: none;">
            <div class="container p-0">
                <div class="col-lg-12">
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <img src="/assets/soragna/blackfriday-css.jpg" alt="BlackFriday" style="max-width:100%;cursor:pointer"
                        class="modal-hover-opacity" />
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--modal popup start-->
<ng-template #newsletter let-modal class="theme-modal-2">
    <div class="modal-content">
        <div class="modal-body">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <img src="/assets/soragna/blackfriday-css.jpg" alt="BlackFriday" style="max-width:100%;cursor:pointer"
                                class="modal-hover-opacity" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->