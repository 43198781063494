<!--footer section -->
<footer [ngClass]="class">
	<div class="light-layout">
		<div class="container">
			<section class="section-b-space border-b">
				<div class="row footer-theme2">
					<div class="col-xl-4 col-md-6 mt-2 pt-2">
						<div class="footer-contant">
							<div class="footer-logo" id="footerlogo">
								<img src="/assets/soragna/logo-small.png" alt="Soragna" style="max-height: 180px;">
							</div>
						</div>
					</div>
					<div class="col-xl-8 col-md-6 mt-2 pt-2">
						<div class="footer-contant">
							<ul class="contact-details">
								<li><i class="fa fa-map-marker"></i>&nbsp; {{ "Strada Provinciale per Diolo 118 - 43019 Soragna (PR)" | translate }}</li> 
								<li><i class="fa fa-phone"></i>&nbsp; <a href="tel:0524599073"> +39 0524 599073</a></li> 
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>

	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center">
					<div class="footer-end">
						<p class="py-1"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} 
							CASEIFICIO SOCIALE 'SORAGNA' Soc. Coop. arl - {{ "P.IVA 00167360346" | translate }}.
							{{ "Tutti i diritti riservati. Vedi la nostra" | translate }} <a [routerLink]="['/privacy']" style="color: #d8c636;">{{ "Privacy Policy" | translate }}.</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->