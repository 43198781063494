import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HomeComponent } from './dp-home/home/home.component';
import { HeaderNavbarComponent } from './dp-home/header-navbar/header-navbar.component';
import { FooterComponent } from './dp-home/footer/footer.component';
import { MenuTopComponent } from './dp-home/header-navbar/menu-top/menu-top.component';
import { SettingsComponent } from './dp-home/header-navbar/settings/settings.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { ServicesComponent } from './dp-home/services/services.component';
import { ProductBoxComponent } from './dp-products/product-box/product-box.component';
import { ProductListComponent } from './dp-products/product-list/product-list.component';
import { ProductDetailComponent } from './dp-products/product-detail/product-detail.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductPaginationComponent } from './dp-products/widgets/product-pagination/product-pagination.component';
import { ProductPriceComponent } from './dp-products/widgets/product-price/product-price.component';
import { ProductBrandsComponent } from './dp-products/widgets/product-brands/product-brands.component';
import { ProductCategoriesComponent } from './dp-products/widgets/product-categories/product-categories.component';
import { ProductBreadcrumbComponent } from './dp-products/widgets/product-breadcrumb/product-breadcrumb.component';
import { ContactComponent } from './dp-contacts/contact/contact.component';
import { ProductGridComponent } from './dp-products/widgets/product-grid/product-grid.component';
import { ProductBoxLargeComponent } from './dp-products/widgets/product-box-large/product-box-large.component';
import { ProductTypeComponent } from './dp-products/widgets/product-type/product-type.component'; 
import { ProductCartComponent } from './dp-cart/product-cart/product-cart.component';
import { ProductCheckoutComponent } from './dp-cart/product-checkout/product-checkout.component';
import { DiscountPipe } from './dp-pipe/discount.pipe';
import { TapToTopComponent } from './dp-widgets/tap-to-top/tap-to-top.component';
import { LoginComponent } from './dp-login/login/login.component';
import { ForgetPasswordComponent } from './dp-login/forget-password/forget-password.component';
import { RegisterComponent } from './dp-login/register/register.component';
import { CartModalComponent } from './dp-modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from './dp-modal/quick-view/quick-view.component';
import { SizeModalComponent } from './dp-modal/size-modal/size-modal.component';
import { SkeletonProductBoxComponent } from './dp-widgets/skeleton-product-box/skeleton-product-box.component';
import { ProductOffersComponent } from './dp-offers/product-offers/product-offers.component';
import { InfoAssistanceComponent } from './dp-modal/info-assistance/info-assistance.component';
import { RelatedProductComponent } from './dp-products/widgets/related-product/related-product.component';
import { ImageZoomComponent } from './dp-modal/image-zoom/image-zoom.component';
import { ProductCheckoutSuccessComponent } from './dp-cart/product-checkout-success/product-checkout-success.component';
import { EmailConfirmComponent } from './dp-modal/email-confirm/email-confirm.component';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyComponent } from './dp-contacts/privacy/privacy.component';
import { CountdownComponent } from './dp-products/widgets/countdown/countdown.component';
import { RightToWithdrawComponent } from './dp-contacts/right-to-withdraw/right-to-withdraw.component';
import { ConditionsSaleComponent } from './dp-contacts/conditions-sale/conditions-sale.component';
import { BankTransferComponent } from './dp-cart/bank-transfer/bank-transfer.component';
import { ProductOfferComponent } from './dp-products/widgets/product-offer/product-offer.component';
import { CookieComponent } from './dp-contacts/cookie/cookie.component';
import { SliderComponent } from './dp-home/slider/slider.component';
import { Slider2Component } from './dp-home/slider2/slider2.component';
import { VisitComponent } from './visit/visit.component';
import { RecipesComponent } from './recipes/recipes.component';
import { SliderVisitComponent } from './dp-home/slider-visit/slider-visit.component';
import { VisitModalComponent } from './dp-modal/visit-modal/visit-modal.component';
import { OrderShippingComponent } from './dp-cart/order-shipping/order-shipping.component';
import { ProductCheckoutUnsuccessfulComponent } from './dp-cart/product-checkout-unsuccessful/product-checkout-unsuccessful.component';
import { PromotionSliderComponent } from './dp-products/widgets/promotion-slider/promotion-slider.component';
import { CancelVisitComponent } from './visit/cancel-visit/cancel-visit.component';
import { SuccessVisitComponent } from './visit/success-visit/success-visit.component';
import { ProductCheckoutConfirmComponent } from './dp-cart/product-checkout-confirm/product-checkout-confirm.component';
 
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderNavbarComponent,
    FooterComponent,
    MenuTopComponent,
    SettingsComponent,
    ServicesComponent,
    ProductBoxComponent,
    ProductListComponent,
    ProductDetailComponent,
    ProductPaginationComponent,
    ProductPriceComponent,
    ProductBrandsComponent,
    ProductCategoriesComponent,
    ProductBreadcrumbComponent,
    ContactComponent,
    ProductGridComponent,
    ProductBoxLargeComponent,
    ProductTypeComponent, 
    ProductCartComponent,
    ProductCheckoutComponent,
    ProductCheckoutConfirmComponent,
    DiscountPipe,
    TapToTopComponent,
    LoginComponent,
    ForgetPasswordComponent,
    RegisterComponent,
    CartModalComponent,
    QuickViewComponent,
    SizeModalComponent,
    SettingsComponent,
    SkeletonProductBoxComponent,
    ProductOffersComponent,
    InfoAssistanceComponent,
    RelatedProductComponent,
    ImageZoomComponent,
    ProductCheckoutSuccessComponent,
    EmailConfirmComponent,
    PrivacyComponent,
    CountdownComponent,
    RightToWithdrawComponent,
    ConditionsSaleComponent,
    BankTransferComponent,
    ProductOfferComponent,
    CookieComponent,
    SliderComponent,
    Slider2Component, 
    VisitComponent,
    RecipesComponent,
    SliderVisitComponent,
    VisitModalComponent,
    OrderShippingComponent,
    ProductCheckoutUnsuccessfulComponent,
    PromotionSliderComponent,
    CancelVisitComponent,
    SuccessVisitComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    NgImageSliderModule,
    NgxPayPalModule,
    Ng5SliderModule,
    InfiniteScrollModule,
    HttpClientModule,
    NgbModule,
    NgxSkeletonLoaderModule,
    BarRatingModule,
    LazyLoadImageModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  providers: [CookieService,DiscountPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
