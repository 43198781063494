import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/dp-products/product.model';
import { ProductService } from 'src/app/dp-services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public products: Product[] = [];
  public search: boolean = false;
  public languageflag: string;
  public showCart = true;

  public srcImg = environment.srcImg;

  todayDate = new Date();

  public languages = [{
    name: 'Italiano',
    code: 'it',
    path: "assets/images/flags/it.jpg"
  }, {
    name: 'English',
    code: 'en',
    path: "assets/images/flags/en.jpg"
  }
  ];

  public currencies = [{
    Name: 'euro',
    Currency: 'EUR',
    Price: 0.90 // price of euro
  },
  {
    Name: 'dollar',
    Currency: 'USD',
    Price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService, private route: ActivatedRoute, private router: Router,
    public productService: ProductService) {
    this.productService.cartItems.subscribe(response => { 
      if (response !== null && response !== undefined) {
        this.products = response;
      } else {
        this.products = [];
      }
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('css-language') === "" || localStorage.getItem('css-language') === undefined || localStorage.getItem('css-language') === null) {
      if (navigator.language === "it-IT" || navigator.language === "it-CH" || navigator.language === "it") {
        this.languageflag = "assets/images/flags/it.jpg";
      } else {
        this.languageflag = "assets/images/flags/en.jpg";
      }
    } else {
      this.languageflag = "assets/images/flags/" + localStorage.getItem('css-language') + ".jpg";
    }
  }

  searchToggle() {
    this.search = !this.search;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      if (code !== localStorage.getItem('css-language')) {
        this.translate.use(code);
        localStorage.setItem('css-language', code);
        localStorage.setItem("products", "")
        this.languageflag = "assets/images/flags/" + code + ".jpg";
        localStorage.setItem("products-update", this.todayDate.toDateString());

        this.productService.changeProductsLang(code).subscribe(next => {
          localStorage['products'] = JSON.stringify(next);
          window.location.reload();
        });

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
          skipLocationChange: false  // do trigger navigation
        })
        //let prodotti: any; 
        //prodotti = this.productService.gethttpProducts().pipe(map(data => data)); 
        //prodotti = prodotti.subscribe(next => { 
        // localStorage['products'] = JSON.stringify(next);
        //window.location.reload();});  

      }
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount(0, 0);
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }
 
}
