import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from 'src/app/dp-services/nav.service';
import { MenuItem } from '../../home-service/menu.model';
import { MenuService } from '../../home-service/menu.service';

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss']
})
export class MenuTopComponent implements OnInit {
  public menuItems: MenuItem[];

  @Output() changeTab = new EventEmitter<string>();

  constructor(private router: Router, public navServices: NavService, private menuService: MenuService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    this.initialize();
  }


  initialize(): void {
    this.menuService.getMenutabs.toPromise().then(response => { 
     this.menuItems = response;
   });
   }
 
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  changeMenu(path: string){
    this.changeTab.emit(path);
  }
}
