import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/dp-services/product.service';
import { Product } from '../product.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {
  public grid: string = 'col-xl-4 col-md-6';
  public layoutView: string = 'grid-view';
  public products: Product[] = [];
  //public brands: any[] = [];
  public types: any[] = [];
  public categories: any[] = [];
  public offers: any[] = [];
  public minPrice: number = 0;
  public maxPrice: number = 5000;
  public tags: any[] = [];
  //public category: string;
  public pageNo: number = 1;
  public paginate: any = {}; // Pagination use only
  public sortBy: string; // Sorting Order
  public mobileSidebar: boolean = false;
  public loader: boolean = true;

  public searchedProduct: Product[] = [];
  public searchInput = "";
  public numOfProducts = 0;
 
  public srcImg = environment.srcImg;
 
  @ViewChild('searchcomponent', { read: ElementRef }) searchcomponent: ElementRef;
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.searchcomponent.nativeElement.contains(event.target)) {
      this.searchInput = "";
      this.searchedProduct = [];
      this.numOfProducts = 0;
    }
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private viewScroller: ViewportScroller,
    public productService: ProductService) {
    // Get Query params..
    this.route.queryParams.subscribe(params => {
      //this.brands = params.brand ? params.brand.split(",") : [];
      this.types = params.subcategory ? params.subcategory.split(",") : [];
      this.categories = params.category ? params.category.split(",") : [];
      this.offers = params.offer ? params.offer.split(",") : [];
      this.minPrice = params.minPrice ? params.minPrice : this.minPrice;
      this.maxPrice = params.maxPrice ? params.maxPrice : this.maxPrice;
      this.tags = [...this.categories, ...this.types, ...this.offers]; // All Tags Array

      //this.category = params.category ? params.category : null;
      this.sortBy = params.sortBy ? params.sortBy : 'ascending';
      this.pageNo = params.page ? params.page : this.pageNo;

      productService.getProducts.subscribe(data => {
        if (data !== undefined) {
          this.products = data;
          // Get Filtered Products.. 
          this.products = this.productService.filterProducts(this.types, this.categories, this.offers)
          // Sorting Filter 
          this.products = this.productService.sortProducts(this.products, this.sortBy);

          // Price Filter
          this.products = this.products.filter(item => item.Price >= this.minPrice && item.Price <= this.maxPrice)
          // Paginate Products
          this.paginate = this.productService.getPager(this.products.length, +this.pageNo);     // get paginate object from service
          this.products = this.products.slice(this.paginate.startIndex, this.paginate.endIndex + 1); // get current page of items
        }
      });
      this.minPrice = 0; //this.productService.getMinPrice();
      this.maxPrice = this.productService.getMaxPrice(); 

    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  // Append filter value to Url
  updateFilter(tags: any) {
    tags.page = null; // Reset Pagination
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: tags,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // SortBy Filter
  sortByFilter(value) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: value ? value : null },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Remove Tag
  removeTag(tag) {
    //this.brands = this.brands.filter(val => val !== tag);
    this.categories = this.categories.filter(val => val !== tag);
    this.types = this.types.filter(val => val !== tag);
    this.offers = this.offers.filter(val => val !== tag);
    
    let params = {
      //brand: this.brands.length ? this.brands.join(",") : null,
      category: this.categories.length ? this.categories.join(",") : null,
      subcategory: this.types.length ? this.types.join(",") : null,
      offer: this.offers.length ? this.offers.join(",") : null
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Clear Tags
  removeAllTags() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // product Pagination
  setPage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: page },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }).finally(() => {
      this.viewScroller.setOffset([120, 120]);
      this.viewScroller.scrollToAnchor('products'); // Anchore Link
    });
  }

  // Change Grid Layout
  updateGridLayout(value: string) {
    //col-lg-2 col-lg-3 col-lg-4 col-lg-6
    this.grid = value;
  }

  // Change Layout View
  updateLayoutView(value: string) {
    //grid-view oppure list-view
    this.layoutView = value;
    if (value == 'list-view')
      this.grid = 'col-lg-12 zoomin';
    else
      this.grid = 'col-xl-4 col-md-6';
  }

  // Mobile sidebar
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  startSearch(event: any) {
    this.searchedProduct = new Array<Product>();
    //event.target.value
    this.productService.getProducts.subscribe(response => {
      response.forEach(product => {
        if (product.Title.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase())) {
          this.searchedProduct.push(product);
        }
      })
    })

    if (event.target.value === "" || event.target.value === null) {
      this.searchedProduct = new Array<Product>();
      this.searchInput = "";
    }

    this.numOfProducts = this.searchedProduct.length;
  }

  changePage(product: Product) {
    //[routerLink]="['/shop/', item.ShortUrl]"
    this.router.navigate(['/shop/', product.ShortUrl]).then(() => window.location.reload());
  }

}
