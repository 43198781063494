import { Component, Input, OnInit } from '@angular/core';
import { HomeSliderPhrase } from '../slider';

@Component({
  selector: 'app-slider2',
  templateUrl: './slider2.component.html',
  styleUrls: ['./slider2.component.scss']
})
export class Slider2Component implements OnInit {
  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;

  constructor() { }

  ngOnInit(): void {
  }

  public HomeSliderConfig: any = HomeSliderPhrase;

}
