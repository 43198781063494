import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Brand       } from '../dp-products/models/brand.model';
import { Category    } from '../dp-products/models/category.model';
import { SubCategory } from '../dp-products/models/subcategory.model';

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {

  constructor(private http: HttpClient, public translate: TranslateService) {}

  getBrands(){
    return this.http.get<Brand[]>(`${environment.paramUrlBase}/api/brands/all`);
  }

  getCategories(){
    var lingua = localStorage.getItem('css-language');
    return this.http.get<Category[]>(`${environment.paramUrlBase}/api/categories/all?lang=${lingua}`);
  }

  getSubCategories(){
    var lingua = localStorage.getItem('css-language');
    return this.http.get<SubCategory[]>(`${environment.paramUrlBase}/api/subcategories/all?lang=${lingua}`);
  }

  getHomeCategories(){
    var lingua = localStorage.getItem('css-language');
    return this.http.get<Category[]>(`${environment.paramUrlBase}/api/categories/home_page?lang=${lingua}`);
  }
}
