<h6 class="product-title">{{ "Offer expires in" | translate }}</h6>
<div class="timer">
    <p id="demo">
      <span style="color: #489eda;">
        <span class="timer-num">{{ days }}</span>
        <span class="padding-l">:</span>
        <span class="timer-cal">{{ "Days" | translate }}</span>
      </span>
      <span  style="color: #6fb866;">
        <span class="timer-num">{{ hours }}</span>
        <span class="padding-l">:</span>
        <span class="timer-cal">{{ "Hrs" | translate }}</span>
      </span>
      <span style="color: #ffb17a;">
        <span class="timer-num">{{ minutes }}</span>
        <span class="padding-l">:</span>
        <span class="timer-cal">{{ "Min" | translate }}</span>
      </span>
      <span style="color: #ff647f;">
        <span class="timer-num">{{ seconds }}</span>
        <span class="timer-cal">{{ "Sec" | translate }}</span>
      </span>
    </p>
</div>