import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  constructor(private http: HttpClient) { }

  saveVisitData(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/visits/new-visit`, data);
  }
 
  cancelReservation(idvisita: any){ 
    return this.http.get<any>(`${environment.paramUrlBase}/api/visits/cancel-visit/${idvisita}`);
  }

  getReservationByID(idvisita: any){ 
    return this.http.get<any>(`${environment.paramUrlBase}/api/visits/detail-visit/${idvisita}`);
  }

  cancelDefinitely(idvisita: any, rimborso: any){ 
    return this.http.get<any>(`${environment.paramUrlBase}/api/visits/cancel-definitely/${idvisita}?id=${rimborso}`);
  }

  payVisit(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/stripe/stripe_visit`, data);
  }

  setVisitTimes(data: string, lingua: string, tot: number){ 
    return this.http.get<any>(`${environment.paramUrlBase}/api/visits/visit_times/availables?visit_date=${data}&visit_lang=${lingua}&n_pers=${tot}`);
  }
}
