<app-product-breadcrumb [title]="'menu.shoponline'" [breadcrumb]="'menu.shoponline'"></app-product-breadcrumb>

<app-promotion-slider [textClass]="'text-center'"
    [buttonText]="'home.buttonshop' | translate"></app-promotion-slider>

<!-- section start -->
<section class="section-b-space">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row row-search" #searchcomponent>
                <div class="col-md-12 p-0 withicon">
                    <input type="search" class="form-control inputsearch" [(ngModel)]="searchInput"
                        placeholder="&#61442;&nbsp;{{'shoponline.CercaProdotto' | translate}}..."
                        (input)="startSearch($event)" />
                </div>
                <!--<div class="col-md-2 btnwithicon p-0 text-center">
                    <button class="btn pt-3"><i class="fa fa-search"></i> &ensp; {{ "Search" | translate }}</button>
                  </div>-->
                <div class="col-md-12 p-0" *ngIf="searchInput !== ''">
                    <div class="list-of-searched" style="max-height: 300px; overflow: auto;">
                        <ul *ngFor="let item of searchedProduct; let i = index">
                            <li (click)="changePage(item)" *ngIf="i < numOfProducts" style="cursor: pointer;">
                                <span *ngIf="item.ImageSrc; else vuoto">
                                    <img src="{{srcImg+item.ImageSrc}}" alt="{{item.ImageSrc}}" style="max-height: 65px;">
                                </span>
                                <ng-template #vuoto>
                                    <img src="/assets/soragna/placeholder.jpg" alt="Nessun prodotto" style="max-height: 65px;">
                                </ng-template>
                                <span>{{item.Title}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="row pt-4">
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i> {{ "shoponline.Filtro" |
                                                translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="product-filter-tags">
                                    <li class="mr-1" *ngFor="let tag of tags">
                                        <a class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length > 0">
                                        <a class="clear_filter" (click)="removeAllTags()">
                                            {{ "shoponline.CancellaTutti" | translate }}
                                        </a>
                                    </li>
                                </ul>
                                <div class="collection-product-wrapper" id="products">
                                    <app-product-grid [products]="products" [paginate]="paginate"
                                        [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)"
                                        (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-product-grid>

                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products"
                                                style="margin-top: 3%">
                                                <app-product-box-large [product]="product"
                                                    [currency]="productService?.Currency" [thumbnail]="false"
                                                    [cartModal]="true" [loader]="true">
                                                </app-product-box-large>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                                                *ngIf="!products.length">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4" alt="Nessun prodotto">
                                                <h3>{{ "shoponline.SpiacentiImpossibileTrovareIlProdottoCheStaiCercando"
                                                    | translate }}!!! </h3>
                                                <p>{{
                                                    "shoponline.ControllaSeHaiScrittoQualcosaInModoErratoOProvaACercareConAltreParole"
                                                    | translate }}.</p>
                                                <a [routerLink]="['/shop']" class="btn btn-solid">{{
                                                    "shoponline.ContinuaShopping" | translate }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <app-product-pagination [products]="products" [paginate]="paginate"
                                        (setPage)="setPage($event)">
                                    </app-product-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                <span class="filter-back">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i> {{ "shoponline.Indietro" |
                                    translate }}
                                </span>
                            </div>
                            <!-- Category filter start 
                            <app-product-categories></app-product-categories>-->
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <app-product-offer [products]="products" [title]="'special offers'" [types]="offers"
                                (offersFilter)="updateFilter($event)"></app-product-offer>
                            <app-product-type [products]="products" [title]="'category'" [types]="categories"
                                (typesFilter)="updateFilter($event)">
                            </app-product-type>
                            <app-product-price [min]="minPrice" [max]="maxPrice" (priceFilter)="updateFilter($event)">
                            </app-product-price>

                        </div>
                        <!-- silde-bar colleps block end here-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->
<app-tap-to-top></app-tap-to-top>