<owl-carousel-o [options]="HomeSliderConfig" class="home-slider" [ngClass]="class">
	<ng-container *ngFor="let slider of sliders">
		<ng-template carouselSlide>
			<div class="home" [ngClass]="textClass" [ngStyle]="{'background-image': 'url('+slider.image+')'}">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="slider-contain" style="justify-content: center;">
								<div>
									<h4>{{slider.title | translate}}</h4>
									<h1>{{slider.subTitle | translate}}</h1>
									<a [routerLink]="['/shop']" class="btn btn-solid" [ngClass]="buttonClass">
										{{buttonText | translate}}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</owl-carousel-o>  