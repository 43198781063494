import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  postMail(postData: any){
    let data = JSON.stringify(postData);
    return this.http.post(`${environment.paramUrlBase}/api/send-email`, data);
  }

  getRightToWithdraw(){
    return  this.http.get<any>('assets/json-data/right-to-withdraw.json');
  }

  getPrivacyPolicy(){
    return  this.http.get<any>('assets/json-data/privacy.json');
  }

  getFAQ(){
    return  this.http.get<any>('assets/json-data/faq.json');
  }
}
