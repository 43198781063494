<app-product-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-product-breadcrumb>

<!-- section start -->
<section class="p-0" *ngIf="errore && carricamentoDati">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="error-section">
                    <h1>404</h1>
                    <h2>page not found</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- thank-you section start -->
<section class="section-b-space pb-0" *ngIf="!errore">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="success-text">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <h3>{{ "shoponline.PaginaVisitSuccess.Grazie" | translate }} {{
                        "shoponline.PaginaVisitSuccess.perAverPrenotato" | translate }}</h3>
                </div>
            </div>
            <div class="col-md-12">
                <div class="product-order success-text">
                    <p class="text"> {{ "shoponline.PaginaVisitSuccess.PuoiContattarciVia" | translate }} &nbsp;<b
                            class="gold-text">
                            &#115;&#097;&#108;&#101;&#115;&#064;&#099;&#097;&#115;&#101;&#105;&#102;&#105;&#099;&#105;&#111;&#045;&#115;&#111;&#114;&#097;&#103;&#110;&#097;&#046;&#105;&#116;
                        </b><br> {{ "shoponline.PaginaCheckoutSuccess.PuoiChiamarci" | translate }}
                        <a href="tel:0524 599073">
                            <span class="gold-text">{{ "info-caseificio.telefono" | translate }}</span>
                        </a> {{ "shoponline.PaginaVisitSuccess.perQualsiasiEsigenza" | translate }}.
                    </p>
                </div>
            </div>
            <div class="ornament pt-4"></div>
        </div>
    </div>
</section>
<!-- Section ends -->

<!-- order-detail section start -->
<section class="section-b-space pt-0" *ngIf="!errore">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="text-center">
                    <h3 class="title-yellow-2">{{ "shoponline.PaginaVisitSuccess.dettagli" | translate }}</h3>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text  p-0 m-0">{{ "shoponline.PaginaVisitSuccess.numPersone" | translate }} :</p>
                    </div>
                    <div class="col-sm-6 text-left">{{visitDetails.Nperson}}</div>
                </div>
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text  p-0 m-0">{{ "shoponline.PaginaVisitSuccess.numBambini" | translate }} :</p>
                    </div>
                    <div class="col-sm-6 text-left">{{visitDetails.Nchild}}</div>
                </div>
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text p-0 m-0">{{ "shoponline.PaginaVisitSuccess.data" | translate }} :</p>
                    </div>
                    <div class="col-sm-6 text-left">{{visitDetails.VisitDate | date: "dd-MM-yyyy"}}</div>
                </div>
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text p-0 m-0">{{ "shoponline.PaginaVisitSuccess.ora" | translate }} :</p>
                    </div>
                    <div class="col-sm-6 text-left">{{visitDetails.VisitTime}}</div>
                </div>
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text p-0 m-0">{{ "shoponline.PaginaVisitSuccess.lingua" | translate }} :</p>
                    </div>
                    <div class="col-sm-6 text-left">{{visitDetails.Language}}</div>
                </div>
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text p-0 m-0">{{ "shoponline.PaginaVisitSuccess.degustazione" | translate }} :</p>
                    </div>
                    <div class="col-sm-6 text-left">
                        <span *ngIf="visitDetails.Tasting == true || visitDetails.Tasting2 == true"> Sì <span
                                *ngIf="visitDetails.Tasting2 == true"> (con anolini) </span></span>
                        <span *ngIf="visitDetails.Tasting == false && visitDetails.Tasting2 == false"> No</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 text-right">
                        <p class="text p-0 m-0">{{ "shoponline.PaginaVisitSuccess.prezzo" | translate }}:</p>
                    </div>
                    <div class="col-sm-6 text-left">{{visitDetails.Total}} &euro;</div>
                </div>
            </div>

        </div>
    </div>
    <div class="row p-2 m-2" style="margin-bottom: 120px;">
        <div class="col-12 text-center pb-4">
            <a [routerLink]="['/']" class="btn btn-solid">{{ "home-link" | translate }}</a>
        </div>
    </div>
</section>
<!-- Section ends -->