<app-product-breadcrumb [title]="'privacy'" [breadcrumb]="'privacy'"></app-product-breadcrumb>

<section class="contact-page section-b-space" style="padding-bottom: 100px;">
    <div class="container">
        <div style="padding-top: 40px;"></div>
        <div class="row section-b-space">

            <div class="collection-content col">
                <div class="page-main-content">
                    <div class="row">
                        <div class="col-sm-12">
                            <h4 class="title-yellow-2 text-center pb-1">{{policy.Privacy.title}}</h4>
                            <div> 
                                <p class="text" [innerHTML]="policy.Privacy.text"> </p>
                                <div class="ornament"></div>
                            </div> 
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text pb-1">{{policy.Info1.title}}</h4>
                            <div> 
                                <p class="text" [innerHTML]="policy.Info1.text1"> </p> 
                                <p class="text" [innerHTML]="policy.Info1.text2"> </p> 
                            </div> 
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text pb-1">{{policy.Info2.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info2.text"> </p> 
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <h4 class="gold-text pb-1">{{policy.Info3.title}}</h4> 
                            <div> 
                                <p class="text" [innerHTML]="policy.Info3.text"> </p> 
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-tap-to-top></app-tap-to-top>