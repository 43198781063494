import { AfterContentInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CartModalComponent } from 'src/app/dp-modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from 'src/app/dp-modal/quick-view/quick-view.component';
import { ProductService } from 'src/app/dp-services/product.service';
import { Product } from '../../product.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-box-large',
  templateUrl: './product-box-large.component.html',
  styleUrls: ['./product-box-large.component.scss']
})
export class ProductBoxLargeComponent implements OnInit, AfterContentInit {

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string;
  public srcImg = environment.srcImg;

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    /*if (this.loader) {
      setTimeout(() => { this.loader = false; }, 800); // Skeleton Loader
    }*/
  }

  ngAfterContentInit(): void {
    this.loader = false;
  }
  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  /*addToCompare(product: any) {
    this.productService.addToCompare(product);
  }*/

}
