import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { VisitService } from 'src/app/dp-services/visit.service';

@Component({
  selector: 'app-cancel-visit',
  templateUrl: './cancel-visit.component.html',
  styleUrls: ['./cancel-visit.component.scss']
})
export class CancelVisitComponent implements OnInit {

  visitDate: any;
  statoVisita = "passata";

  @ViewChild("visitaCancellata", { static: false }) visitaCancellata: TemplateRef<any>;

  constructor(
    private httpVisit: VisitService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
    var visitID = this.route.snapshot.params.visit_id;
    this.httpVisit.cancelReservation(visitID).toPromise().then(response => {
      if (response) { 
        this.visitDate = response;
        var today = new Date();
        var visitdate = new Date(response.VisitDate)
        var twodaysbefore = new Date(response.VisitDate);
        twodaysbefore.setDate(twodaysbefore.getDate() - 2);
        if (today <= twodaysbefore) {
          this.statoVisita = "rimborsabile" 
        } else if (today > visitdate) {
          this.statoVisita = "passata" 
        } else {
          this.statoVisita = "cancellabile"; 
        }
      }
    })
  }

  cancelVisit() {
    var rimborso = 2
    if (this.statoVisita == "rimborsabile") {
      rimborso = 1
    }
    this.httpVisit.cancelDefinitely(this.visitDate.ID, rimborso).toPromise().then(response => {
      if (response) {
        this.modalService.open(this.visitaCancellata, { 
          size: 'lg',
          ariaLabelledBy: 'visitaCancellata-Modal',
          centered: true,
          windowClass: 'theme-modal newsletterm NewsLetterModal'
        }).result.then((result) => {
         
        }, (reason) => {
          this.router.navigate(['/'])
        });
      }
    })
  }
}
